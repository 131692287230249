import React from "react";
import Modal from "../../components/Modal";

interface InstructionsProps {
    open: boolean;
    onClose: () => void;
}

const InstructionsModal: React.FC<InstructionsProps> = ({
    open,
    onClose
}) => {
    return (
        <Modal open={open}>
            <div className="wc-instructions">
                <h3 style={{ margin: 0 }}>Instructions</h3>
                <div className="close-btn" onClick={onClose}>
                    ❌
                </div>
                <ul>
                    <li>Any user above 18 years of age can participate in this quiz.</li>
                    <li>The quiz must be attempted in a single stretch, no breaks will be allowed.</li>
                    <li>There will be a 90 second time limit to the quiz.</li>
                    <li>Each user can attempt the quiz only once in 24 hours.</li>
                    <li>The result of the quiz will be displayed on the leaderboard.</li>
                    <li>The leaderboard will be refreshed everyday.</li>
                    <li>The quiz will be of moderate difficulty.</li>
                    <li>Top 10 users will be chosen and displayed on the leaderboard.</li>
                    <li>The winners will be announced on Entri social media accounts </li>
                    <li>Prizes will only be awarded to the Top 10 users. </li>
                    <li>To appear in the leaderboard, necessary information should be entered by the player.</li>
                    <li>Score will be based on correct answers and time taken.</li>
                    <li>Negative marks will be there for incorrect answers.</li>
                </ul>
            </div>
        </Modal>
    )
}

export default InstructionsModal;