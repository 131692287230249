export function getSuperscript(rank: number): "st" | "nd" | "rd" | "th" {
  const tenthReminder = rank % 10,
    hundredthReminder = rank % 100;
  if (tenthReminder === 1 && hundredthReminder !== 11) {
    return "st";
  }
  if (tenthReminder === 2 && hundredthReminder !== 12) {
    return "nd";
  }
  if (tenthReminder === 3 && hundredthReminder !== 13) {
    return "rd";
  }
  return "th";
}
