import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getWCLeaderboard } from "../../app/api/worldCupQuiz";
import { AppThunk, RootState } from "../../app/store";
import { LeaderBoard } from "../SpokenEnglish/model";

export module WCLeaderBoard {
  export interface Score {
    name: string;
    score: number;
  }
  export interface initialState {
    data: Score[];
    isLoading: boolean;
    hasError: boolean;
  }
}

const initialState: LeaderBoard.initialState = {
  data: [],
  isLoading: false,
  hasError: false,
};

export const wcLeaderboardSlice = createSlice({
  name: "wcLeaderboard",
  initialState,
  reducers: {
    fetchLeaderBoard: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchLeaderBoardSuccess: (
      state,
      action: PayloadAction<LeaderBoard.Score[]>
    ) => {
      state.data = action.payload;
      state.isLoading = false;
      state.hasError = false;
    },
    fetchLeaderBoardFailed: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setLeaderBoard: (state, action: PayloadAction<LeaderBoard.Score[]>) => {
      state.data = action.payload;
    },
    resetLeaderBoard: (state) => initialState,
  },
});

export const {
  fetchLeaderBoard,
  fetchLeaderBoardFailed,
  fetchLeaderBoardSuccess,
  resetLeaderBoard,
  setLeaderBoard,
} = wcLeaderboardSlice.actions;

export const selectLeaderBoard = (state: RootState) => state.wcleaderboard;

export default wcLeaderboardSlice.reducer;

export const loadLeaderBoard = (): AppThunk => async (dispatch) => {
  dispatch(fetchLeaderBoard());
  try {
    const response = await getWCLeaderboard();

    const { data } = response.data;
    dispatch(
      fetchLeaderBoardSuccess(
        data.sort((first, second) => second.score - first.score)
      )
    );
  } catch (error) {
    dispatch(fetchLeaderBoardFailed());
  }
};
