import clsx from "clsx";
import { debounce, reject, shuffle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementLife,
  decrementScore,
  incrementScore,
  selectScore,
} from "../spokenEnglishSlice";

type FillInTheBlankProps = {
  question: string[];
  options: string[];
  onGameFinish: () => void;
};

type ChoiceOption = { choice: string; isWrong: boolean };

const FillInTheBlanks: React.FC<FillInTheBlankProps> = ({
  question,
  options,
  onGameFinish,
}) => {
  const dispatch = useDispatch();
  const { score } = useSelector(selectScore);
  const [choices, setChoices] = useState<ChoiceOption[]>();
  const [selectedChoice, setSelectedChoice] = useState<string>();
  const [onHoveredChoice, setMouseHoveredChoice] = useState<string>();
  const buttonRefs = useRef<HTMLButtonElement[]>([]);
  const isUserPresses = useRef(false);
  useEffect(() => {
    setChoices(
      shuffle(options).map((option) => ({
        choice: option,
        isWrong: false,
      }))
    );
    return () => {
      setChoices([]);
    };
  }, []);

  const onChoiceClick = debounce(async (value: ChoiceOption) => {
    if (isUserPresses.current) return;
    setSelectedChoice(value.choice);
    if (buttonRefs.current) {
      buttonRefs.current.forEach((element) =>
        element?.setAttribute("disabled", "disabled")
      );
    }

    if (value.choice !== options[0]) {
      const newChoices = choices?.map((choice) => {
        if (choice.choice === value.choice) {
          return { choice: choice.choice, isWrong: true };
        } else {
          return choice;
        }
      });
      setChoices(newChoices);
      removeElement(newChoices!);
      isUserPresses.current = true;
      dispatch(decrementLife());
      dispatch(decrementScore(50));
    } else {
      isUserPresses.current = true;
      let nextScore = 100;
      if (score === 0) {
        nextScore -= (3 - (choices?.length || 0)) * 50;
      }
      dispatch(incrementScore(nextScore));
      setTimeout(() => {
        onGameFinish();
      }, 900);
    }
  }, 300);

  const onMouseOver = debounce(function (value: ChoiceOption) {
    setMouseHoveredChoice(value.choice);
  }, 400);

  const onMouseLeave = debounce(function () {
    setMouseHoveredChoice(undefined);
  }, 400);

  function removeElement(newChoices: ChoiceOption[]) {
    setTimeout(() => {
      const choicesAfterRemoved = newChoices.filter(
        (choice) => !choice.isWrong
      );
      if (choicesAfterRemoved.length !== newChoices.length) {
        setChoices(choicesAfterRemoved);
      }
      isUserPresses.current = false;
      if (buttonRefs.current) {
        buttonRefs.current.forEach((element) =>
          element?.removeAttribute("disabled")
        );
      }
    }, 400);
  }

  const handleButtonRefCallback = (element: HTMLButtonElement | null) => {
    if (element) buttonRefs.current?.push(element);
  };
  return (
    <div className="fill-in-blanks">
      <div className="question-container">
        <div className="question-statement">
          {question[0]}
          <span
            className={clsx(
              "blank",
              onHoveredChoice && "hover",
              selectedChoice && "active",
              selectedChoice === options[0] && "correct",
              selectedChoice && selectedChoice !== options[0] && "wrong"
            )}
          >
            {selectedChoice || onHoveredChoice}
          </span>
          {question[1]}
        </div>
        <div className="choice-container">
          {choices?.map((option) => (
            <button
              key={option.choice}
              className={clsx(
                "choice",
                option.isWrong && "wrong",
                selectedChoice === option.choice &&
                  !option.isWrong &&
                  selectedChoice === options[0] &&
                  "correct"
              )}
              disabled={option.isWrong}
              onClick={() => onChoiceClick(option)}
              onMouseOver={() => onMouseOver(option)}
              onMouseLeave={() => onMouseLeave()}
              ref={handleButtonRefCallback}
            >
              {option.choice}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FillInTheBlanks;
