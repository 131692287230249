import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GameOverScreen from "./components/GameOver";
import Life from "./components/Life";
import ScoreHeader from "./components/ScoreHeader";
import StartScreen from "./components/StartScreen";
import GamePlay from "./components/GamePlay";
import { resetGame } from "./spokenEnglishSlice";

import "./styles.scss";
import { gameFinishEvent, gameVisitEvent } from "./events";
import { useNavigate } from "react-router-dom";

const Playground: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [gameStatus, setGameStatus] = useState<"START" | "GAME" | "OVER">(
    "START"
  );
  const [gameOverReason, setGameOverReason] = useState<
    "COMPLETED" | "GAMEOVER"
  >("COMPLETED");

  const handleOnStartClick = () => {
    dispatch(resetGame());
    localStorage.setItem("se-attempt", "0");
    setGameStatus("GAME");
  };

  useEffect(() => {
    gameVisitEvent();
    document.title = "Entri | Spoken English Past Tense";
  }, []);

  const handleGameOver = (reason: "COMPLETED" | "GAMEOVER") => {
    if (gameStatus === "START") return;
    const attemptNo = Number(localStorage.getItem("se-attempt") || 0);
    localStorage.setItem("se-attempt", String(attemptNo + 1));
    gameFinishEvent();
    setTimeout(() => {
      setGameStatus("OVER");
    }, 800);
    setTimeout(() => {
      navigate("./leaderboard", { replace: true });
    }, 1800);
    setGameOverReason(reason);
  };

  const getCurrentGameStatus = () => {
    switch (gameStatus) {
      case "START":
        return <StartScreen onStart={handleOnStartClick} />;
      case "GAME":
        return <GamePlay onGameOver={handleGameOver} />;
      case "OVER":
        return (
          <GameOverScreen
            onStart={handleOnStartClick}
            gameOverReason={gameOverReason}
          />
        );
      default:
        return (
          <GameOverScreen
            onStart={handleOnStartClick}
            gameOverReason={gameOverReason}
          />
        );
    }
  };

  return (
    <div className="playground">
      <Life onGameOver={handleGameOver} />
      <ScoreHeader />
      {getCurrentGameStatus()}
    </div>
  );
};

export default Playground;
