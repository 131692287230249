import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postUserDetails } from "../../app/api/simpleQuiz";
import EndScreen from "./components/EndScreen";
import GamePlay from "./components/Gameplay";
import StartScreen from "./components/StartScreen";
import UserFrom from "./components/UserFrom";
import { selectScore } from "./slice";
import "./styles.scss";

export interface IAppProps {}
const QUIZ_SLUG = "campus";

export default function FunTimeQuiz(props: IAppProps) {
  const score = useSelector(selectScore);
  const [gameStatus, setGameStatus] = useState<
    "START" | "DETAIL" | "GAME" | "END"
  >("START");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.body.style.background = "#212121";
    return () => {
      document.body.style.background = "none";
    };
  }, []);

  const onStartScreenPlayNow = () => {
    const userData = localStorage.getItem("valentines-day-user-data");
    if (!userData) setGameStatus("DETAIL");
    else setGameStatus("GAME");
  };

  const userFormSubmit = async (name: string, phone: string) => {
    try {
      setIsLoading(true);
      localStorage.setItem(
        "valentines-day-user-data",
        JSON.stringify({ name, phone })
      );
      const response = await postUserDetails(QUIZ_SLUG, {
        name,
        phone,
        score,
      });
      setIsLoading(false);
      if (response) setGameStatus("GAME");
    } catch (error) {
      console.log(error);
    }
  };

  const handleGameFinish = async (
    reason: "COMPLETED" | "GAMEOVER",
    userScore: number
  ) => {
    setGameStatus("END");
    try {
      setIsLoading(true);
      const userData = localStorage.getItem("valentines-day-user-data");
      if (userData) {
        const data = JSON.parse(userData);
        await postUserDetails(QUIZ_SLUG, {
          name: data?.name || "",
          phone: data?.phone || "",
          score: userScore,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const screens = new Map<"START" | "DETAIL" | "GAME" | "END", ReactElement>([
    ["START", <StartScreen onPlayNow={onStartScreenPlayNow} />],
    ["DETAIL", <UserFrom isLoading={isLoading} onSubmit={userFormSubmit} />],
    ["GAME", <GamePlay onGameOver={handleGameFinish} />],
    ["END", <EndScreen />],
  ]);

  return <div className="simple-quiz">{screens.get(gameStatus)}</div>;
}
