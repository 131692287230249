import React from "react";

import Badge1 from "../../../assets/images/badge1.png";
import Badge2 from "../../../assets/images/badge2.png";
import Badge3 from "../../../assets/images/badge3.png";
import Badge4 from "../../../assets/images/badge4.png";
import Badge5 from "../../../assets/images/badge5.png";
import EntriLogo from "../../..//assets/images/entri_text_logo.png";
import { LeaderBoard } from "../model";
import { isEmpty } from "../../../app/utils";

const badges = [Badge1, Badge2, Badge3, Badge4, Badge5];

type Props = {
  shareData: any[];
  currentUser?: LeaderBoard.UsersDetail;
};

const ShareComponent = React.forwardRef<HTMLDivElement, Props>(
  ({ shareData, currentUser }, ref) => {
    const badgeIcon = badges[Math.floor(Math.random() * 5)];
    // const [userDetail, setUserDetail] = useState<LeaderBoard.UsersDetail>()

    return (
      <div className="share-leaderboard" ref={ref}>
        <div className="content">
          <div className="entri-text-logo">
            <img src={EntriLogo} alt="badge" />
          </div>
          <h5 className="title">Leaderboard</h5>
          <div className="user-details">
            <div className="image-container">
              <img src={badgeIcon} alt="badge" />
            </div>
            <div className="text-container">
              <h6 className="name">{currentUser?.name}</h6>
              <div className="scores">
                <span className="score">
                  Score: <b>{currentUser?.score}</b>
                </span>
                <span className="divider"></span>
                <span className="rank">
                  Rank: <b>{currentUser?.rank}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="top-scores">
            <h6>Top scorers</h6>
            <table>
              <tbody>
                {shareData && shareData.length > 0 ? (
                  shareData.map((element, index) =>
                    !isEmpty(element.score) ? (
                      <tr
                        key={element.id}
                        className={element.mine ? "highlight" : ""}
                      >
                        <td>{index + 1}</td>
                        <td>{element.name}</td>
                        <td>{element.score}</td>
                      </tr>
                    ) : (
                      <React.Fragment />
                    )
                  )
                ) : (
                  <tr
                    key={currentUser?.id}
                    className={currentUser?.mine ? "highlight" : ""}
                  >
                    <td>{1}</td>
                    <td>{currentUser?.name}</td>
                    <td>{currentUser?.score}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
);

export default ShareComponent;
