import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import seGameStatusReducer from "../features/SpokenEnglish/spokenEnglishSlice";
import leaderBoardReducer from "../features/SpokenEnglish/leaderboardSlice";
import wcLeaderboardReducer from "../features/WorldCupQuiz/leaderboardSlice";
import valentinesDayReducer from "../features/ValentinesDay/slice";
import campusQuizReducer from "../features/SESimpleQuiz/slice";

export const store = configureStore({
  reducer: {
    // gameState: gameStateReducer,
    seGameStatus: seGameStatusReducer,
    leaderboard: leaderBoardReducer,
    wcleaderboard: wcLeaderboardReducer,
    valentinesDay: valentinesDayReducer,
    campusQuiz: campusQuizReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
