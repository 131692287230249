import { AxiosResponse } from "axios";
import httpService from "./httpService";

export function postUserDetails(
  slug: string,
  userDetails: {
    name: string;
    phone: string | number;
    score: number;
  }
): Promise<AxiosResponse<{ data: { message: string; rank: number } }>> {
  return httpService.post(`public-games/${slug}/leaderboard/`, userDetails);
}
