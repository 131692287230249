import clsx from "clsx";
import React, { useEffect, useState } from "react";
import WorldCupQuiz from "../../../app/model/WorldCupQuiz";
import EGCard from "../../../components/Card/Card";
import EGSelectableButton from "../../../components/SelectableButton/SelectableButton";

interface LevelProps {
    question: WorldCupQuiz.RootObject;
    onSelectOption: (optionIndex: number) => void;
}

const Level: React.FC<LevelProps> = ({
    question,
    onSelectOption,
}) => {
    const { id, description, options } = question;

    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>();

    useEffect(() => {
        setSelectedOptionIndex(undefined);
    }, [id])

    const handleOptionClick = (optionIndex: number) => {
        setSelectedOptionIndex(optionIndex);
        setTimeout(() => onSelectOption(optionIndex), 500)
    }

    return (
        <div className="wc-level">
            <EGCard>
                {description}
            </EGCard>
            <div
                className="wc-options-container"
                style={{ marginTop: 24 }}
            >
                {options.map((option, index) => {
                    return (
                        <div
                            key={index}
                            className={clsx('wc-option', {
                                'animate__animated animate__bounceIn': selectedOptionIndex === index && option.isCorrect,
                                'animate__animated animate__shakeX': selectedOptionIndex === index && !option.isCorrect,
                            })}
                        >
                            <EGSelectableButton
                                variant={selectedOptionIndex === index ?
                                    option.isCorrect ? 'green' : 'red'
                                    : 'blue'}
                                style={{
                                    height: '100%',
                                    width: '100%'
                                }}
                                selected={selectedOptionIndex === index}
                                onClick={() => handleOptionClick(index)}
                            >
                                <span>{option.label}</span>
                            </EGSelectableButton>
                        </div>
                    )
                })}
            </div>
        </div >
    );
}

export default Level;