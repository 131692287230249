import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export const MAX_LIFE = 3;

const initialState = {
  life: MAX_LIFE,
  prevScore: 0,
  score: 0,
  isResuming: false,
};

export const seGameStatusSlice = createSlice({
  name: "seGameStatus",
  initialState,
  reducers: {
    incrementScore: (state, action: PayloadAction<number>) => {
      state.prevScore = state.score;
      state.score += action.payload;
    },
    decrementScore: (state, action: PayloadAction<number>) => {
      state.prevScore = state.score;
      state.score = Math.max(0, state.score - action.payload);
    },
    forceSetScore: (state, action: PayloadAction<number>) => {
      state.prevScore = state.score;
      state.score = action.payload;
    },
    incrementLife: (state) => {
      state.life = Math.min(3, state.life + 1);
    },
    decrementLife: (state) => {
      state.life = Math.max(0, state.life - 1);
    },
    resetGame: (state) => initialState,
  },
});

export const {
  incrementLife,
  decrementLife,
  incrementScore,
  decrementScore,
  forceSetScore,
  resetGame,
} = seGameStatusSlice.actions;

export const selectScore = (state: RootState) => ({
  score: state.seGameStatus.score,
  prevScore: state.seGameStatus.prevScore,
});
export const selectLife = (state: RootState) => state.seGameStatus.life;

export default seGameStatusSlice.reducer;
