import React from "react";

interface InfoPageProps {
    info: string;
    variant?: 'small' | 'large',
}

const InfoPage: React.FC<InfoPageProps> = ({ 
    info,
    variant = 'small',
 }) => {

    return (
        <span
            style={{
                textAlign: 'center',
                fontFamily: 'Bungee',
                fontSize: variant === 'small' ? '1rem' : '4rem',
            }}
        >
            {info}
        </span>
    )
}

export default InfoPage;