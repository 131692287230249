export const questionCount = 10;

export const Teams: { [key: string]: Team } = {
    BR: { name: "Brazil", flag: '🇧🇷' },
    AR: { name: "Argentina", flag: '🇦🇷' },
    SA: { name: "Saudi Arabia", flag: '🇸🇦' },
    PR: { name: "Portugal", flag: '🇵🇹' },
    GE: { name: "Germany", flag: '🇩🇪' },
    JA: { name: "Japan", flag: '🇯🇵' },
    FR: { name: "France", flag: '🇫🇷' },
    SP: { name: "Spain", flag: '🇪🇸' },
    EN: { name: "England", flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿' },
    SK: { name: "South Korea", flag: '🇰🇷' },
    NE: { name: "Netherlands", flag: '🇳🇱' },
    BE: { name: "Belgium", flag: '🇧🇪' },
    UR: { name: "Uruguay", flag: '🇺🇾' },
    CR: { name: "Croatia", flag: '🇭🇷' },
    WA: { name: "Wales", flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
    PO: { name: "Poland", flag: '🇵🇱' },
    QA: { name: "Qatar", flag: '🇶🇦' },
    EC: { name: "Ecuador", flag: '🇪🇨' },
    SN: { name: "Senegal", flag: '🇸🇳' },
    US: { name: "USA", flag: '🇺🇸' },
    GH: { name: "Ghana", flag: '🇬🇭' },
    IR: { name: "Iran", flag: '🇮🇷' },
    ME: { name: "Mexico", flag: '🇲🇽' },
    TU: { name: "Tunisia", flag: '🇹🇳' },
    AU: { name: "Australia", flag: '🇦🇺' },
    DE: { name: "Denmark", flag: '🇩🇰' },
    CS: { name: "Costa Rica", flag: '🇨🇷' },
    MO: { name: "Morocco", flag: '🇲🇦' },
    CA: { name: "Canada", flag: '🇨🇦' },
    SW: { name: "Switzerland", flag: '🇨🇭' },
    SE: { name: "Serbia", flag: '🇷🇸' },
    CM: { name: "Cameroon", flag: '🇨🇲' },
}
export interface Team {
    name: string;
    flag: string;
}