import { sendMoengageEvent } from "../../app/tracker/moengage";

function getUserData() {
  const userData = localStorage.getItem("past-tense-user-data");
  if (userData) {
    const { name = "", phone = "" } = JSON.parse(userData);
    return {
      name,
      phone,
    };
  } else return;
}

export function gameVisitEvent() {
  const userData = getUserData();
  if (userData) {
    sendMoengageEvent("SE Games Visit", {
      "Game Source": "Web",
      "User Name": userData.name,
      Phone: userData.phone,
    });
    return;
  }
  sendMoengageEvent("SE Games Visit", {
    "Game Source": "Web",
  });
}

export function scoresVisitEvent() {
  const userData = getUserData();
  if (userData) {
    sendMoengageEvent("SE Scorecard Visited", {
      "Game Source": "Web",
      "User Name": userData.name,
      Phone: userData.phone,
    });
    return;
  }
  sendMoengageEvent("SE Scorecard Visited", {
    "Game Source": "Web",
  });
}

export function shareClickedEvent() {
  const userData = getUserData();
  if (userData) {
    sendMoengageEvent("SE Share Clicked", {
      "Game Source": "Web",
      "User Name": userData.name,
      Phone: userData.phone,
    });
    return;
  }
  sendMoengageEvent("SE Share Clicked", {
    "Game Source": "Web",
  });
}

export function gameFinishEvent() {
  const attemptNo = Number(localStorage.getItem("se-attempt") || 1);
  const userData = getUserData();
  if (userData) {
    sendMoengageEvent("SE Games Finished", {
      "Game Source": "Web",
      "User Name": userData.name,
      Phone: userData.phone,
      "Attempt No": attemptNo,
    });
    return;
  }
  sendMoengageEvent("SE Games Finished", {
    "Game Source": "Web",
    "Attempt No": attemptNo,
  });
}

export function gameRetryEvent() {
  const attemptNo = Number(localStorage.getItem("se-attempt") || 1);
  const userData = getUserData();
  if (userData) {
    sendMoengageEvent("SE Games Retry Clicked", {
      "Game Source": "Web",
      "User Name": userData.name,
      Phone: userData.phone,
      "Attempt No": attemptNo,
    });
    return;
  }
  sendMoengageEvent("SE Games Retry Clicked", {
    "Game Source": "Web",
    "Attempt No": attemptNo,
  });
}
