import './Header.scss'

const Header = () => {
    return (
        <header>
            <p className="small">Kerala PSC</p>
            <p className="large">Daily game</p>
        </header>
    )
}

export default Header;