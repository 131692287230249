import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import clsx from "clsx";
import UserDetailsModal from "../../components/UserDetails.tsx";
import TrophyIcon from "../../assets/images/trophy.svg";
import { getSuperscript } from "../SpokenEnglish/utils";
import { loadLeaderBoard, selectLeaderBoard } from "./leaderboardSlice";
import { generateWCShareText, getScore, getScoreArray, getWCGameStats } from "./utils";
import { postWCUserDetails } from "../../app/api/worldCupQuiz";
import EGSelectableButton from "../../components/SelectableButton/SelectableButton";
import EntriLogo from "../../assets/images/entri_logo_black_text.svg";
import { shareAttemptData } from "../../app/utils";
import { sendMoengageEvent } from "../../app/tracker/moengage";
import EGButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

type Props = {};

const WCLeaderBoard = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const leaderboard = useSelector(selectLeaderBoard);
  const gameStats = getWCGameStats();
  const score = getScore();

  const [rank, setRank] = useState<number>();
  const [showUserModal, setShowUserModal] = useState(false);

  useEffect(() => {
    // scoresVisitEvent();

    document.title = "Entri | FIFA World Cup 2022 Quiz | Scores";
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const displayUserModal = () => {
      timeout = setTimeout(() => {
        setShowUserModal(true);
      }, 2000);
    };
    const userData = localStorage.getItem("wc-user-data");
    if (!userData && score > 0) displayUserModal();
    return () => {
      if (timeout) clearInterval(timeout);
    };
  }, []);

  useEffect(() => {
    const userData = localStorage.getItem("wc-user-data");
    if (!userData) return;
    const saveUserDetails = async () => {
      try {
        const { name = "", phone = "", language = "" } = JSON.parse(userData);
        const response = await postWCUserDetails({ name, phone, language, score });
        if (response.data) {
          setRank(response.data.data.rank);
          dispatch(loadLeaderBoard());
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (score > 0) saveUserDetails();
  }, [score]);

  useEffect(() => {
    if (!dispatch) return;
    dispatch(loadLeaderBoard());
  }, [dispatch]);

  const saveUserDetails = async (
    name: string,
    phone: string,
    language: string,
    score: number
  ) => {
    try {
      const response = await postWCUserDetails({ name, phone, language, score });
      if (response.data) {
        if (response.data) {
          setRank(response.data.data.rank);
          dispatch(loadLeaderBoard());
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnUserSubmit = (name: string, phone: string, language?: string) => {
    if (!language) return
    localStorage.setItem(
      "wc-user-data",
      JSON.stringify({ name, phone, language })
    );
    if (score >= 0) saveUserDetails(name, phone, language, score);
    setShowUserModal(false);
  };

  const handleClickShare = () => {
    if (!gameStats) return
    const shareText = generateWCShareText(gameStats.attemptData, gameStats.remainingTime);
    shareAttemptData(shareText);
    // Send Share event
    sendMoengageEvent("WC Quiz Share Clicked", {
      "Score": score,
    });
  }

  const handleClickDownload = () => {
    const win = window.open('https://entri.sng.link/Bcofz/436a/6luh', '_blank');
    if (win != null) {
      win.focus();
    }
  }

  const handleViewAnswers = () => {
    navigate('/fifa/answers/')
  }

  return (
    <div className="wc-leaderboard">
      <div className="particle-circle inner" />
      <div className="particle-circle outer" />
      <h2 className="score">
        Your
        <br /> score
      </h2>
      <div className="score-container">
        <div className="score-circle">{score}</div>
        {rank && (
          <React.Fragment>
            <span className="divider"></span>
            <div className="rank-circle">
              <p>
                {rank}
                <sup>{getSuperscript(rank)}</sup>
              </p>
              <span>Rank</span>
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="leaderboard-list">
        <table>
          <tbody>
            {!leaderboard.isLoading &&
              leaderboard.data &&
              leaderboard.data?.map((board, index) => (
                <tr
                  key={`${board.name}-${index}`}
                  className={clsx(rank === index + 1 && "highlight")}
                >
                  <td>{index + 1}</td>
                  <td className="align-left">
                    {board.name}{" "}
                    {index < 3 && <img src={TrophyIcon} alt="trophy" />}{" "}
                  </td>
                  <td>{board.score}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="btn-container">
        <div style={{ display: 'flex', columnGap: 16 }}>
          {/* <EGButton className="blue small" onClick={handleViewAnswers}>
            Answers
          </EGButton> */}
          <EGButton className="small" onClick={handleClickShare}>
            Share
          </EGButton>
        </div>
        <EGSelectableButton
          variant='blue'
          style={{ width: '90%' }}
          onClick={handleClickDownload}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <span style={{ textAlign: 'start' }}>
              Learn and Expand your knowledge with Entri
            </span>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              <img src={EntriLogo} height={30} />
              <img
                style={{ margin: -6 }}
                width={120}
                alt='Get it on Google Play'
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
              />
            </div>
          </div>
        </EGSelectableButton>
      </div>
      <UserDetailsModal
        open={showUserModal}
        onClose={() => setShowUserModal(false)}
        onSubmit={handleOnUserSubmit}
      />
    </div>
  );
};

export default WCLeaderBoard;
