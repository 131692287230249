import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
// import Cookie from 'js-cookie';
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";

const { REACT_APP_BASE_URL } = process.env;

// axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: REACT_APP_BASE_URL,
  withCredentials: true,
});

const onRequestSuccess = (config: AxiosRequestConfig) => {
  const isFormData = config.data instanceof FormData;
  if (config.data && !isFormData) {
    config.data = snakeCaseKeys(config.data, { deep: true });
  }
  if (config.params) {
    config.params = snakeCaseKeys(config.params, { deep: true });
  }
  return config;
};

const onRequestFail = (error: AxiosError) => {
  return Promise.reject(error);
};

instance.interceptors.request.use(onRequestSuccess, onRequestFail);

const onResponseSuccess = (response: AxiosResponse) => {
  return camelCaseKeys(response, { deep: true });
};

const onResponseFail = (error: AxiosError) => {
  if (error.response) {
    // currentUser is ignored because on the first page load
    // 403 error is displayed for invalid credential.

    // 400 Bad Request Error
    if (error.response.status === 400) {
      console.log(error.message);
    }

    // 404 not found Error
    if (error.response.status === 404) {
      console.log(error.message);
    }

    // 4xx Errors
    if (error.response.status > 400 && error.response.status < 500) {
      console.log(error.message);
    }

    // 5xx Errors
    if (error.response.status >= 500 && error.response.status <= 599) {
      console.log(error.message);
    }
  }
  // return Promise.reject(error);
};
instance.interceptors.response.use(onResponseSuccess, onResponseFail);
export default instance;
