import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, batch, useSelector } from "react-redux";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortEnd,
} from "react-sortable-hoc";
import { Verb } from "../past-tense";
import {
  decrementLife,
  decrementScore,
  incrementScore,
  selectScore,
} from "../spokenEnglishSlice";

type MatchVerbProps = {
  verbs: Verb[];
  pastTense: Verb[];
  onGameFinished: () => void;
};

const SortableItem = SortableElement<{ value: Verb; isPastTense?: boolean }>(
  ({ value, isPastTense }: { value: Verb; isPastTense?: boolean }) => {
    return (
      <div
        className={clsx(
          "element",
          value.isCorrect && "correct",
          value.isMovedAndWrong && "wrong"
        )}
      >
        {isPastTense ? value.past : value.verb}
      </div>
    );
  }
);
const SortableList = SortableContainer<{
  items: Verb[];
  isPastTense?: boolean;
}>(
  ({
    items,
    isPastTense = false,
  }: {
    items: Verb[];
    isPastTense?: boolean;
  }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.verb}`}
            index={index}
            value={value}
            isPastTense={isPastTense}
          />
        ))}
      </ul>
    );
  }
);

const MatchVerb: React.FC<MatchVerbProps> = ({
  onGameFinished,
  verbs,
  pastTense,
}) => {
  const dispatch = useDispatch();
  const { score } = useSelector(selectScore);

  const [verbsList, setVerbsList] = useState<Verb[]>([]);
  const [pastTenseList, setPastTenseList] = useState<Verb[]>([]);
  const incorrectMovements = useRef(0);

  const isUserDragging = useRef(false);

  useEffect(() => {
    setVerbsList(verbs);
    setPastTenseList(pastTense);
  }, []);

  const handleOnMoveVerb = ({ oldIndex, newIndex }: SortEnd) => {
    if (isUserDragging.current) return;
    if (oldIndex === newIndex) return;
    const newVerbs = arrayMove(verbsList!, oldIndex, newIndex);
    const newPastTense = [...pastTenseList];
    let correctAnswerCount = 0;

    newVerbs.forEach((verb, index) => {
      if (verb.past === newPastTense[index].past) {
        newVerbs[index].isCorrect = newPastTense[index].isCorrect = true;
        correctAnswerCount++;
      }
    });

    // Update score and life
    if (correctAnswerCount === 0) {
      newVerbs[newIndex].isMovedAndWrong = newPastTense[
        newIndex
      ].isMovedAndWrong = true;
      if (score === 0) incorrectMovements.current += 1;
      batch(() => {
        dispatch(decrementLife());
        dispatch(decrementScore(50));
      });
    } else {
      let nextScore = correctAnswerCount * 100;
      if (score === 0) {
        nextScore -= incorrectMovements.current * 50;
        incorrectMovements.current = 0;
      }
      dispatch(incrementScore(nextScore));
    }
    isUserDragging.current = true;
    // Update the content
    setVerbsList(newVerbs);
    setPastTenseList(newPastTense);
    setTimeout(() => {
      removeCorrectQuestion(newVerbs, newPastTense);
    }, 900);
  };
  const handleOnMovePastTense = ({ oldIndex, newIndex }: SortEnd) => {
    if (isUserDragging.current) return;
    if (oldIndex === newIndex) return;

    const newPastTense = arrayMove(pastTenseList!, oldIndex, newIndex);
    const newVerbs = [...verbsList];
    let correctAnswerCount = 0;

    newPastTense.forEach((verb, index) => {
      if (verb.verb === newVerbs[index].verb) {
        newVerbs[index].isCorrect = newPastTense[index].isCorrect = true;
        correctAnswerCount++;
      }
    });

    isUserDragging.current = true;
    // Update score and life
    if (correctAnswerCount === 0) {
      newPastTense[newIndex].isMovedAndWrong = newVerbs[
        newIndex
      ].isMovedAndWrong = true;
      if (score === 0) incorrectMovements.current += 1;
      batch(() => {
        dispatch(decrementLife());
        dispatch(decrementScore(50));
      });
    } else {
      let nextScore = correctAnswerCount * 100;
      if (score === 0) {
        nextScore -= incorrectMovements.current * 50;
        incorrectMovements.current = 0;
      }
      dispatch(incrementScore(nextScore));
    }

    // Update the content
    setVerbsList(newVerbs);
    setPastTenseList(newPastTense);
    setTimeout(() => {
      removeCorrectQuestion(newVerbs, newPastTense);
    }, 900);
  };

  const removeCorrectQuestion = (
    newVerbList: Verb[],
    newPastTenseList: Verb[]
  ) => {
    const filteredVerbs = newVerbList
      .filter((verb) => !verb.isCorrect)
      .map((verb) => ({ ...verb, isMovedAndWrong: false }));
    const filteredPastTense = newPastTenseList
      .filter((verb) => !verb.isCorrect)
      .map((verb) => ({ ...verb, isMovedAndWrong: false }));
    setVerbsList(filteredVerbs);
    setPastTenseList(filteredPastTense);
    isUserDragging.current = false;
    if (filteredVerbs.length === 0) onGameFinished();
  };
  return (
    <div className="verbs">
      <h2 className="title">
        Match <br />
        the following
      </h2>
      <div className="verbs-match">
        {verbsList && (
          <SortableList
            onSortEnd={handleOnMoveVerb}
            items={verbsList}
            helperClass="element-active"
          />
        )}
        {pastTenseList && (
          <SortableList
            onSortEnd={handleOnMovePastTense}
            items={pastTenseList}
            helperClass="element-active"
            isPastTense
          />
        )}
      </div>
    </div>
  );
};

export default MatchVerb;
