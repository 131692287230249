import React from "react";
import { isEmpty } from "../../../app/utils";

type Props = {
  usersData: any[];
  totalUsers: number;
};

const RankTable: React.FC<Props> = ({ usersData, totalUsers }) => {
  return (
    <div className="table-container">
      <table className="general-leaderboard hidden">
        <tbody>
          {usersData.map((data, index) =>
            data.name ? (
              <tr key={data.rank} className={data.mine ? "highlight" : ""}>
                <td>{data.rank}</td>
                <td>
                  <div className="name-container">
                    <div className="avatar">
                      {data.display_picture ? (
                        <img src={data.display_picture} alt={data.name} />
                      ) : (
                        data.name?.charAt(0)
                      )}
                    </div>
                    <div>
                      <span className="name">{data.name}</span>
                      {data.mine && !isEmpty(data.score) && (
                        <span className="desc">
                          Your rank is #{data.rank} out of {totalUsers} students
                        </span>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  {!isEmpty(data.score) ? (
                    data.score
                  ) : (
                    <span className="text-not-attempted">Not attempted</span>
                  )}
                </td>
              </tr>
            ) : (
              <tr className="empty">
                <td></td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RankTable;
