import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { loadLeaderBoard, selectLeaderBoard } from "./leaderboardSlice";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { selectScore } from "./spokenEnglishSlice";
import UserDetailsModal from "../../components/UserDetails.tsx";
import { postUserDetails } from "../../app/api/past-tense";
import TrophyIcon from "../../assets/images/trophy.svg";
import { getSuperscript } from "./utils";
import { scoresVisitEvent, shareClickedEvent } from "./events";
import { shareGameScore } from "../../app/utils";

type Props = {};

const LeaderBoard = (props: Props) => {
  const dispatch = useAppDispatch();
  const leaderboard = useSelector(selectLeaderBoard);
  const { score } = useSelector(selectScore);
  const [rank, setRank] = useState<number>();
  const [showUserModal, setShowUserModal] = useState(false);

  useEffect(() => {
    scoresVisitEvent();

    document.title = "Entri | Spoken English Past Tense | Scores";
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const displayUserModal = () => {
      timeout = setTimeout(() => {
        setShowUserModal(true);
      }, 2000);
    };
    const userData = localStorage.getItem("past-tense-user-data");
    if (!userData && score > 0) displayUserModal();
    return () => {
      if (timeout) clearInterval(timeout);
    };
  }, []);

  useEffect(() => {
    const userData = localStorage.getItem("past-tense-user-data");
    if (!userData) return;
    const saveUserDetails = async () => {
      try {
        const { name = "", phone = "" } = JSON.parse(userData);
        const response = await postUserDetails({ name, phone, score });
        if (response.data) {
          setRank(response.data.data.rank);
          dispatch(loadLeaderBoard());
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (score > 0) saveUserDetails();
  }, [dispatch, score]);

  useEffect(() => {
    if (!dispatch) return;
    dispatch(loadLeaderBoard());
  }, [dispatch]);

  const saveUserDetails = async (
    name: string,
    phone: string,
    score: number
  ) => {
    try {
      const response = await postUserDetails({ name, phone, score });
      if (response.data) {
        if (response.data) {
          setRank(response.data.data.rank);
          dispatch(loadLeaderBoard());
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleOnUserSubmit = (name: string, phone: string) => {
    localStorage.setItem(
      "past-tense-user-data",
      JSON.stringify({ name, phone })
    );
    if (score >= 0) saveUserDetails(name, phone, score);
    setShowUserModal(false);
  };

  const shareScore = () => {
    shareClickedEvent();
    shareGameScore(score);
  };

  return (
    <div className="leaderboard">
      <div className="particle-circle inner" />
      <div className="particle-circle outer" />
      <h2 className="score">
        Your
        <br /> score
      </h2>
      <div className="score-container">
        <div className="score-circle">{score}</div>
        {rank && (
          <React.Fragment>
            <span className="divider"></span>
            <div className="rank-circle">
              <p>
                {rank}
                <sup>{getSuperscript(rank)}</sup>
              </p>
              <span>Rank</span>
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="leaderboard-list">
        <table>
          <tbody>
            {!leaderboard.isLoading &&
              leaderboard.data &&
              leaderboard.data?.map((board, index) => (
                <tr
                  key={`${board.name}-${index}`}
                  className={clsx(rank === index + 1 && "highlight")}
                >
                  <td>{index + 1}</td>
                  <td className="align-left">
                    {board.name}{" "}
                    {index < 3 && <img src={TrophyIcon} alt="trophy" />}{" "}
                  </td>
                  <td>{board.score}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="btn-container">
        <button
          className={clsx(
            "btn btn-save-score",
            (!rank || score <= 0) && "display"
          )}
          onClick={() => setShowUserModal(true)}
        >
          Save your score
        </button>
        <button
          className={clsx(
            "btn btn-save-score",
            (rank || score > 0) && "visible"
          )}
          onClick={shareScore}
        >
          Share your score
        </button>
        <Link to="/past-tense">
          <button className="btn-spoken start">
            {score === 0 ? "Play" : "Play again"}
          </button>
        </Link>
      </div>
      <UserDetailsModal
        open={showUserModal}
        onClose={() => setShowUserModal(false)}
        onSubmit={handleOnUserSubmit}
      />
    </div>
  );
};

export default LeaderBoard;
