import WorldCupQuiz from "../../app/model/WorldCupQuiz";

export const questions: WorldCupQuiz.RootObject[] = [
    {
        id: 1,
        description: "Which is the host country of the 2022 FIFA World Cup?",
        options: [
            {
                label: 'Germany',
            },
            {
                label: 'Brazil',
            },
            {
                label: 'Qatar',
                isCorrect: true,
            },
            {
                label: 'Russia',
            },
        ]
    },
    {
        id: 2,
        description: "Qatar and FIFA have unveiled as the official Mascot for this year’s FIFA World Cup 2022?",
        options: [
            {
                label: 'Perry',
            },
            {
                label: 'La\'eeb',
                isCorrect: true,
            },
            {
                label: 'Byju\'s',
            },
            {
                label: 'Bing Dwen Dwen',
            },
        ]
    },
    {
        id: 3,
        description: "How many teams will play in the FIFA World Cup 2022?",
        options: [
            {
                label: '32',
                isCorrect: true,
            },
            {
                label: '36',
            },
            {
                label: '16',
            },
            {
                label: '42',
            },
        ]
    },
    {
        id: 4,
        description: "What is the name of the ball in the FIFA World Cup 2022?",
        options: [
            {
                label: 'Adidas Al Rihla',
                isCorrect: true,
            },
            {
                label: 'Adidas Brazuca',
            },
            {
                label: 'Adidas Telstar',
            },
            {
                label: 'None of the above',
            },
        ]
    },
    {
        id: 5,
        description: "Which stadium in Qatar is made up of shipping containers?",
        options: [
            {
                label: 'Ahmad Bin Ali Stadium',
            },
            {
                label: '974 Stadium',
                isCorrect: true
            },
            {
                label: 'Al Thumama Stadium',
            },
            {
                label: 'Al Bayt Stadium',
            },
        ]
    },
    {
        id: 6,
        description: "Which nation will play the 2022 World Cup after 64 years of Waiting?",
        options: [
            {
                label: 'USA',
            },
            {
                label: 'Wales',
                isCorrect: true
            },
            {
                label: 'South Africa',
            },
            {
                label: 'Canada',
            },
        ]
    },
    {
        id: 7,
        description: "Which team was the first country to qualify for the FIFA World Cup 2022?",
        options: [
            {
                label: 'Denmark',
            },
            {
                label: 'Brazil',
            },
            {
                label: 'Germany',
            },
            {
                label: 'Qatar',
                isCorrect: true
            },
        ]
    },
    {
        id: 8,
        description: "How many matches will be played in the FIFA World Cup 2022?",
        options: [
            {
                label: '64',
                isCorrect: true
            },
            {
                label: '65',
            },
            {
                label: '32',
            },
            {
                label: 'None of the above',
            },
        ]
    },
    {
        id: 9,
        description: "The first female referee to take charge of a men\'s FIFA World Cup game?",
        options: [
            {
                label: 'Karen Diaz Medina',
            },
            {
                label: 'Yamashita Yoshimi',
            },
            {
                label: 'Stephanie Frappart',
                isCorrect: true
            },
            {
                label: 'Neuza Back',
            },
        ]
    },
    {
        id: 10,
        description: "How many African nations will take part in the 2022 World Cup?",
        options: [
            {
                label: '3',
            },
            {
                label: '5',
                isCorrect: true
            },
            {
                label: '6',
            },
            {
                label: '2',
            },
        ]
    },
    {
        id: 11,
        description: "Who is the current President of FIFA?",
        options: [
            {
                label: 'Sepp Blatter',
            },
            {
                label: 'Gianni Infantino',
                isCorrect: true
            },
            {
                label: 'Issa Hyatou',
            },
            {
                label: 'Pele',
            },
        ]
    },
    {
        id: 12,
        description: "When will the finals of the 2022 World Cup in Qatar be played?",
        options: [
            {
                label: 'December 20, 2022',
            },
            {
                label: 'December 21, 2022',
            },
            {
                label: 'December 18, 2022',
                isCorrect: true
            },
            {
                label: 'December 28, 2022',
            },
        ]
    },
    // {
    //     id: 13,
    //     description: "Consider the following statements {'\n'}>A) The price of FIFA World Cup Trophy is $20 million \r\nB) The prize money for the winners of FIFA World Cup 2022 is $42 million",
    //     options: [
    //         {
    //             label: 'A Only',
    //         },
    //         {
    //             label: 'A and B',
    //             isCorrect: true
    //         },
    //         {
    //             label: 'B Only',
    //         },
    //         {
    //             label: 'None of the above',
    //         },
    //     ]
    // },
    {
        id: 14,
        description: "Who is the youngest player in the FIFA World Cup 2022?",
        options: [
            {
                label: 'Gavi',
            },
            {
                label: 'Youssoufa Moukoko',
                isCorrect: true
            },
            {
                label: 'Alfredo Talavera',
            },
            {
                label: 'Kylian Mbappe',
            },
        ]
    },
    {
        id: 15,
        description: "In which country/countries FIFA 2026 will be held?",
        options: [
            {
                label: 'Germany',
            },
            {
                label: 'U.K, Mexico and Canada',
            },
            {
                label: 'United States, Mexico and Canada',
                isCorrect: true
            },
            {
                label: 'America',
            },
        ]
    },
    {
        id: 16,
        description: "What is the name of the 2022 World Cup official song?",
        options: [
            {
                label: 'Waka Waka',
            },
            {
                label: 'Hayya Hayya',
                isCorrect: true
            },
            {
                label: 'La Copa de la Vida',
            },
            {
                label: 'The Time of Our Lives',
            },
        ]
    },
    {
        id: 17,
        description: "Official sponsor of FIFA World Cup 2022 is/are?",
        options: [
            {
                label: 'Byju’s',
            },
            {
                label: 'Qatar Energy',
            },
            {
                label: 'Crypto',
            },
            {
                label: 'All of these',
                isCorrect: true
            },
        ]
    },
    {
        id: 18,
        description: "What is the rank of India in FIFA 2022?",
        options: [
            {
                label: '102',
            },
            {
                label: '104',
            },
            {
                label: '106',
                isCorrect: true
            },
            {
                label: '101',
            },
        ]
    },
    {
        id: 19,
        description: "Which country will not play in the FIFA World Cup 2022, as suspended?",
        options: [
            {
                label: 'France',
            },
            {
                label: 'Iraq',
            },
            {
                label: 'Russia',
                isCorrect: true
            },
            {
                label: 'Croatia',
            },
        ]
    },
    {
        id: 20,
        description: "Which country hosted the FIFA World Cup 2018?",
        options: [
            {
                label: 'Brazil',
            },
            {
                label: 'Russia',
                isCorrect: true
            },
            {
                label: 'Canada',
            },
            {
                label: 'France',
            },
        ]
    },
]