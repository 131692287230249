import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import Life from "./Life";
import { questions } from "../util";
import { incrementQuestion, incrementScore, selectScore } from "../slice";
import Question from "./Question";
import { secondsToMS } from "../../../app/utils";
type GamePlayProps = {
  onGameOver: (reason: "COMPLETED" | "GAMEOVER", score: number) => void;
};

const GamePlay: React.FC<GamePlayProps> = ({ onGameOver }) => {
  const [countDown, setCountDown] = useState(60);
  const swiper = useRef<SwiperClass>();
  const score = useSelector(selectScore);
  const dispatch = useDispatch();

  useEffect(() => {
    // exit early when we reach 0
    if (!countDown) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setCountDown((prevDuration) => {
        return prevDuration - 1;
      });
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [countDown]);

  useEffect(() => {
    if (countDown === 0) {
      onGameOver("GAMEOVER", score);
    }
  }, [countDown, onGameOver, score]);

  const handleOnQuestionChoiceClick = useCallback(
    (choiceId: number, isCorrectAnswer: boolean) => {
      if (isCorrectAnswer) dispatch(incrementScore());
      setTimeout(() => {
        if (!swiper.current) return;
        if (!swiper.current.isEnd) {
          swiper.current.slideNext();
          dispatch(incrementQuestion(swiper.current.activeIndex + 1));
        } else {
          console.log(score);
          onGameOver("COMPLETED", isCorrectAnswer ? score + 10 : score);
        }
      }, 500);
    },
    [score, dispatch, onGameOver]
  );

  const handleOnNextClick = () => {
    setTimeout(() => {
      if (!swiper.current) return;
      if (!swiper.current.isEnd) {
        swiper.current.slideNext();
        dispatch(incrementQuestion(swiper.current.activeIndex + 1));
      } else {
        onGameOver("COMPLETED", score);
      }
    }, 400);
  };

  return (
    <div className={clsx("game-play")}>
      <div className="header">
        <Life />
        <span className="timer">{secondsToMS(countDown)} sec</span>
      </div>
      <Swiper
        className="swiper"
        spaceBetween={50}
        slidesPerView={1}
        noSwiping={true}
        noSwipingClass="swiper-slide"
        mousewheel={{
          invert: false,
        }}
        keyboard={{
          enabled: false,
        }}
        onSwiper={(SwiperClass) => (swiper.current = SwiperClass)}
        style={{ width: "100%" }}
      >
        {questions?.map((question, index) => {
          return (
            <SwiperSlide key={index}>
              <Question
                question={question}
                onQuestionClick={handleOnQuestionChoiceClick}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="btn-holder">
        <button className="btn btn-raised" onClick={handleOnNextClick}>
          Next
        </button>
      </div>
    </div>
  );
};

export default GamePlay;
