import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLife } from "../spokenEnglishSlice";
import HeartIcon from "./HeartIcon";

type LifeProps = {
  onGameOver: (reason: "COMPLETED" | "GAMEOVER") => void;
};

const Life: React.FC<LifeProps> = ({ onGameOver }) => {
  const life = useSelector(selectLife);

  useEffect(() => {
    if (life === 0) onGameOver("GAMEOVER");
  }, [life]);

  return (
    <div className="life">
      {new Array(3)
        .fill("")
        .map((_, index) =>
          index + 1 <= life ? (
            <HeartIcon isEmpty={false} key={`life-${index}`} />
          ) : (
            <HeartIcon isEmpty key={`life-${index}`} />
          )
        )}
    </div>
  );
};

export default Life;
