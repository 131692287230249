import React, { useState } from "react";
import { LANGUAGES } from "../../app/constants";
import Modal from "../Modal";
import "./styles.scss";

type UserDetailProps = {
  open: boolean;
  onClose?: () => void;
  onSubmit: (name: string, phone: string, language?: string) => void;
};

const UserDetailsModal: React.FC<UserDetailProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [language, setLanguage] = useState<string>("ml");
  const handleSubmit = () => {
    onSubmit(name!, phone!, LANGUAGES[language].textEn);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <h6 className="modal-title">Enter details to see your rank</h6>
      <div className="form">
        <form>
          <div className="form-group mb-sm-3">
            <input
              type="text"
              id="name"
              name="name"
              placeholder=" "
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label className="floating-label">Your Name</label>
          </div>

          <div className="form-group mb-sm-3">
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder=" "
              pattern="[789][0-9]\d{8,11}$"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <label className="floating-label">Phone Number</label>
          </div>
          <div className="form-group mb-sm-3">
            <select
              name="languages"
              id="lang"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              {Object.keys(LANGUAGES).map((key) => {
                return (<option
                  value={key}
                  key={key}
                >
                  {LANGUAGES[key].text}
                </option>)
              })}
            </select>
          </div>
        </form>
        <div className="btn-container">
          <button className="btn" onClick={() => onClose?.()}>
            Cancel
          </button>
          <button
            className="btn submit"
            onClick={handleSubmit}
            disabled={
              !name.trim() || !phone.trim() || !/[6789][0-9]\d{8,11}/.test(phone)
            }
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
