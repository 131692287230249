import { ValentinesDay } from "./models";

export const questions: ValentinesDay.Question[] = [
  {
    id: 1,
    questionStatement:
      "Unable to think or act normally, especially as a result of being in love",
    options: [
      {
        id: 1,
        choiceStatement: "Awestruck",
        isCorrect: false,
      },
      {
        id: 2,
        choiceStatement: "Moonstruck",
        isCorrect: true,
      },
      {
        id: 3,
        choiceStatement: "Sunstruck",
        isCorrect: false,
      },
      {
        id: 4,
        choiceStatement: "Starstruck",
        isCorrect: false,
      },
    ],
  },
  {
    id: 2,
    questionStatement: "Identify the odd one",
    options: [
      {
        id: 1,
        choiceStatement: "Admire",
        isCorrect: false,
      },
      {
        id: 2,
        choiceStatement: "Adore",
        isCorrect: false,
      },
      {
        id: 3,
        choiceStatement: "Praise",
        isCorrect: false,
      },
      {
        id: 4,
        choiceStatement: "Loathe",
        isCorrect: true,
      },
    ],
  },
  {
    id: 3,
    questionStatement: "The right spelling of the given word is ",
    options: [
      {
        id: 1,
        choiceStatement: "Bouquet",
        isCorrect: true,
      },
      {
        id: 2,
        choiceStatement: "Buqet",
        isCorrect: false,
      },
      {
        id: 3,
        choiceStatement: "Bokqet",
        isCorrect: false,
      },
      {
        id: 4,
        choiceStatement: "Buoquet",
        isCorrect: false,
      },
    ],
  },
  {
    id: 4,
    questionStatement: "The word antonymous with ‘crush’ is",
    options: [
      {
        id: 1,
        choiceStatement: "Puppy love",
        isCorrect: false,
      },
      {
        id: 2,
        choiceStatement: "Passing fancy",
        isCorrect: false,
      },
      {
        id: 3,
        choiceStatement: "Calf love",
        isCorrect: false,
      },
      {
        id: 4,
        choiceStatement: "Detest",
        isCorrect: true,
      },
    ],
  },
  {
    id: 2,
    questionStatement: "A phrase that can replace a “date” is",
    options: [
      {
        id: 1,
        choiceStatement: "Get married",
        isCorrect: false,
      },
      {
        id: 2,
        choiceStatement: "Going out with someone",
        isCorrect: true,
      },
      {
        id: 3,
        choiceStatement: "Be in a committed relationship",
        isCorrect: false,
      },
      {
        id: 4,
        choiceStatement: "Stop seeing each other",
        isCorrect: false,
      },
    ],
  },
];
