// @src/components/Modal.jsx

import React, { ReactNode, useRef } from "react";
import ReactModal from "react-modal";
import "./styles.scss";

type ModalProps = {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
};

const Modal: React.FC<ModalProps> = ({ children, open, onClose }) => {
  const appElement = useRef<HTMLDivElement>(null);
  return (
    <div ref={appElement}>
      <ReactModal
        isOpen={open}
        onRequestClose={onClose}
        contentLabel="Modal"
        appElement={appElement.current!}
        className={{
          base: "modal-base",
          afterOpen: "modal-base_after-open",
          beforeClose: "modal-base_before-close",
        }}
        overlayClassName={{
          base: "overlay-base",
          afterOpen: "overlay-base_after-open",
          beforeClose: "overlay-base_before-close",
        }}
        shouldCloseOnOverlayClick={true}
        closeTimeoutMS={400}
      >
        {children}
      </ReactModal>
    </div>
  );
};

export default Modal;
