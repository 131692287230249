import React from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { selectScore } from "../spokenEnglishSlice";

const ScoreHeader = () => {
  const { score, prevScore } = useSelector(selectScore);
  return (
    <div className="score">
      <h6>Score</h6>
      <CountUp duration={1} start={prevScore} end={score} />
    </div>
  );
};

export default ScoreHeader;
