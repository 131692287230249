import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./features/Home";
import Analysis from "./features/Analysis/Analysis";

import "./features/Home.scss";
import Playground from "./features/SpokenEnglish/Playground";
import LeaderBoard from "./features/SpokenEnglish/LeaderBoard";
import WorldCupQuiz from "./features/WorldCupQuiz/Index";
import WCAnswers from "./features/WorldCupQuiz/WCAnswers";
import WCLeaderBoard from "./features/WorldCupQuiz/WCLeaderboard";
import QuizLeaderboard from "./features/Leaderboard/Leaderboard";
import ValentinesDay from "./features/ValentinesDay/index";
import FunTimeQuiz from "./features/SESimpleQuiz";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="answers" element={<Analysis />} />

        {/* World Cup Quiz */}
        <Route path="/fifa" element={<WorldCupQuiz />} />
        <Route path="/fifa/answers" element={<WCAnswers />} />
        <Route path="/fifa/leaderboard" element={<WCLeaderBoard />} />
        {/* <Route path="*" element={<Home />} /> */}

        <Route path="/past-tense" element={<Playground />} />
        <Route path="/past-tense/leaderboard" element={<LeaderBoard />} />
        <Route path="/quiz/leaderboard" element={<QuizLeaderboard />} />
        <Route path="/valentines" element={<ValentinesDay />} />
        <Route path="/campus" element={<FunTimeQuiz />} />
      </Routes>
    </div>
  );
}

export default App;
