import { format } from "date-fns";
import { useEffect, useState } from "react";
import { getRandomQuestions } from "../../app/api/worldCupQuiz";
import WorldCupQuiz from "../../app/model/WorldCupQuiz";
import StartScreen from "./StartScreen";
import QuizScreen from "./QuizScreen";
import { cloneDeep } from "lodash";
import { questionCount } from "./constants";
import { sendMoengageEvent } from "../../app/tracker/moengage";
import { getScoreArray } from "./utils";
import './styles.scss'
import 'animate.css';
import WCLeaderBoard from "./WCLeaderboard";

export interface QuestionAttemptData extends WorldCupQuiz.RootObject {
  selectedOption?: number;
}

export interface WCGameStats {
  date: string;
  status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED';
  remainingTime: number;
  attemptData: QuestionAttemptData[];
}

function WorldCupQuizPlayground() {
  const [gameStats, setGameStats] = useState<WCGameStats>();

  const setInitialData = () => {
    const questions: WorldCupQuiz.RootObject[] = getRandomQuestions(questionCount);
    const initialStats: WCGameStats = {
      date: format(new Date(), 'dd/MM/yyyy'),
      remainingTime: 90,
      status: 'NOT_STARTED',
      attemptData: questions,
    }
    setGameStats(initialStats)
    localStorage.setItem('wc_game_stats', JSON.stringify(initialStats));

    // Send WC Game visit Event
    sendMoengageEvent('WC Quiz Visit');
  }

  useEffect(() => {
    const gameStatsFromStorage = localStorage.getItem('wc_game_stats');
    if (gameStatsFromStorage) {
      const gameStatsObject = JSON.parse(gameStatsFromStorage) as WCGameStats;
      if (gameStatsObject.date === format(new Date(), 'dd/MM/yyyy')) {
        setGameStats(gameStatsObject)
      } else {
        setInitialData();
      }
    } else {
      setInitialData();
    }

    const timer = setInterval(() => {
      setGameStats((prev) => {
        if (prev && prev.status === 'IN_PROGRESS') {
          return {
            ...prev,
            remainingTime: prev.remainingTime - 1,
          }
        }
        return prev;
      })
    }, 1000);

    return () => clearInterval(timer);
  }, [])

  useEffect(() => {
    if (!gameStats) return
    if (gameStats.remainingTime === 0) {
      setGameStats((prev) => {
        if (prev) {
          return {
            ...prev,
            status: 'COMPLETED'
          }
        }
      })
    }
  }, [gameStats?.remainingTime])

  if (!gameStats) return <></>;

  const { attemptData, remainingTime, status } = gameStats;

  const handleSelectOption = (questionIndex: number, answerIndex: number) => {
    setGameStats((prev) => {
      let newData = cloneDeep(prev);
      if (newData) {
        newData.attemptData[questionIndex].selectedOption = answerIndex;
        if (questionIndex === questionCount - 1) {
          newData.status = 'COMPLETED';
          const scores = getScoreArray(newData.attemptData)
          const goalCount = scores.reduce((total, cur) => {
            return cur ? total + 1 : total;
          }, 0);
          // Send WC Game completed Event
          sendMoengageEvent("WC Quiz Complete", {
            "Goal Count": goalCount,
          });
        };
        // Store to local Storage
        localStorage.setItem('wc_game_stats', JSON.stringify(newData));
        return newData;
      }
    })
  }

  const handleStartGame = () => {
    setGameStats((prev) => {
      if (prev)
        return {
          ...prev,
          status: 'IN_PROGRESS',
        }
    })
  }

  const getCurrentGameStatus = () => {
    switch (status) {
      case 'NOT_STARTED':
        return <StartScreen onStartClick={handleStartGame} />
      default:
      case 'IN_PROGRESS':
        return (
          <QuizScreen
            timeRemaining={remainingTime}
            attemptData={attemptData}
            onSelectOption={handleSelectOption}
          />
        )
      case 'COMPLETED':
        return (
          // <CompletedScreen
          //   attemptData={attemptData}
          // />
          <WCLeaderBoard />
        );
    }
  }

  return (
    <div className="world-cup-game">
      <div className="wc-header">
        <p className="small">World Cup 2022</p>
        <p className="large">Daily Quiz</p>
      </div>
      <div className="wc-content">
        {getCurrentGameStatus()}
      </div>
    </div>
  );
}

export default WorldCupQuizPlayground;
