import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Leader from "./components/Leader";
import RankTable from "./components/RankTable";
import html2canvas from "html2canvas";
import "./styles.scss";

// Badges
import ShareComponent from "./components/ShareComponent";
import { LeaderBoard } from "./model";

type Props = {};

const QuizLeaderboard = (props: Props) => {
  const { search } = useLocation();
  const shareRef = useRef<HTMLDivElement>(null);
  const params = new URLSearchParams(search);
  const category = params.get("category");
  const [communityLeaderboard, setCommunityLeaderboard] = useState<any[]>([]);
  const [leaderboard, setLeaderBoard] = useState<LeaderBoard.UsersDetail[]>([]);
  // const [isData, setIsData] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);

  const [goldUser, silverUser, bronzeUser, ...usersData] = useMemo(
    () => (leaderboard ? leaderboard : []),
    [leaderboard]
  );
  const handleOnShare = (shareLink: string) => {
    if (!shareRef.current) return;
    try {
      html2canvas(shareRef.current).then(async function (canvas) {
        const dataUrl = canvas.toDataURL();
        const base64 = dataUrl.split(";base64,")[1];
        (window as any)?.Android?.onGetShareLink?.(base64);
        //
      });
    } catch {}
  };

  useEffect(() => {
    // Call when page is rendered
    (window as any)?.Android?.onPageReady?.();

    // Call from android when leaderboard data is collected
    (window as any).onLeaderboard = (
      data: string,
      totalCount: string = "0"
    ) => {
      if (typeof data === "string") {
        const parsedLeaderBoard = JSON.parse(data);
        setLeaderBoard(parsedLeaderBoard);
        if (category === "COMMUNITY")
          setCommunityLeaderboard(
            Array.isArray(parsedLeaderBoard)
              ? parsedLeaderBoard.slice(0, 10)
              : []
          );
      }
      setTotalUsers(parseInt(totalCount, 10));
    };
    // Call from android when leaderboard data is collected
    (window as any).onCommunityLeaderboard = (data: string) => {
      if (typeof data === "string") {
        const parsedLeaderBoard = JSON.parse(data);
        setCommunityLeaderboard(
          Array.isArray(parsedLeaderBoard) ? parsedLeaderBoard.slice(0, 10) : []
        );
      }
    };
    // Handle call from android on share community leaderboard
    (window as any).onShare = (shareLink: string) => {
      handleOnShare(shareLink);
    };
  }, [category]);

  const currentUser = useMemo(() => {
    const userIndex = leaderboard?.findIndex((element) => element.mine);
    return userIndex >= 0
      ? { ...leaderboard[userIndex], rank: userIndex + 1 }
      : undefined;
  }, [leaderboard]);

  return (
    <div className="quiz-leaderboard">
      <div className="main">
        <div className="top-users hidden">
          {silverUser && (
            <Leader
              position="silver"
              name={silverUser.name!}
              rank={2}
              score={silverUser.score!}
              displayPicture={silverUser.display_picture}
            />
          )}
          {goldUser && (
            <Leader
              position="gold"
              name={goldUser.name!}
              rank={1}
              score={goldUser.score!}
              displayPicture={goldUser.display_picture}
            />
          )}
          {bronzeUser && (
            <Leader
              position="bronze"
              name={bronzeUser.name!}
              rank={3}
              score={bronzeUser.score!}
              displayPicture={bronzeUser.display_picture}
            />
          )}
        </div>
        {usersData && usersData.length > 0 && (
          <RankTable usersData={usersData} totalUsers={totalUsers} />
        )}
      </div>
      {currentUser && (
        <div className="share-container">
          <ShareComponent
            shareData={communityLeaderboard}
            currentUser={currentUser}
            ref={shareRef}
          />
        </div>
      )}
    </div>
  );
};

export default QuizLeaderboard;
