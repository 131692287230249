import { SimpleQuiz } from "../../types/SimpleQuiz";

const questions: SimpleQuiz.Question[] = [
  {
    id: 1,
    questionStatement: "Rahul ___ Riya on Valentine’s day.",
    options: [
      {
        id: 1,
        choiceStatement: "Proposed",
        isCorrect: false,
      },
      {
        id: 2,
        choiceStatement: "Proposed to",
        isCorrect: true,
      },
      {
        id: 3,
        choiceStatement: "Proposing",
        isCorrect: false,
      },
    ],
  },
  {
    id: 2,
    questionStatement: "Bilal lives ___ the third floor.",
    options: [
      {
        id: 1,
        choiceStatement: "In",
        isCorrect: false,
      },
      {
        id: 2,
        choiceStatement: "On",
        isCorrect: true,
      },
      {
        id: 3,
        choiceStatement: "At",
        isCorrect: false,
      },
    ],
  },
  {
    id: 3,
    questionStatement: "Saniya’s ___ are very supportive of her",
    options: [
      {
        id: 1,
        choiceStatement: "Sisters-in-law",
        isCorrect: true,
      },
      {
        id: 2,
        choiceStatement: "Sister-in-laws",
        isCorrect: false,
      },
      {
        id: 3,
        choiceStatement: "Sisters-in-laws",
        isCorrect: false,
      },
    ],
  },
  {
    id: 4,
    questionStatement: "We have to ___ the project report in three days.",
    options: [
      {
        id: 1,
        choiceStatement: "Give in",
        isCorrect: false,
      },
      {
        id: 2,
        choiceStatement: "Leave in",
        isCorrect: false,
      },
      {
        id: 3,
        choiceStatement: "Turn in",
        isCorrect: true,
      },
    ],
  },
  {
    id: 5,
    questionStatement:
      "Words that sound alike but are different in their meaning and spelling are called",
    options: [
      {
        id: 1,
        choiceStatement: "Homophones",
        isCorrect: true,
      },
      {
        id: 2,
        choiceStatement: "Synonyms",
        isCorrect: false,
      },
      {
        id: 3,
        choiceStatement: "Homonyms",
        isCorrect: false,
      },
    ],
  },
];

export default questions;
