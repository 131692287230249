import React, { useEffect, useState } from "react";
import clsx from "clsx";
import GamePlayState, { GameStatistics } from "../GamePlay/models";
import { format } from "date-fns";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Keyboard, Mousewheel } from "swiper";
import EGCard from "../../components/Card/Card";
import { getQuestionAttemptIndicator } from "../GameStats/GameStats";
import { isEmpty } from "lodash";
import EGButton from "../../components/Button/Button";
import { sendMoengageEvent } from "../../app/tracker/moengage";
import { generateEntriGameShareText, shareAttemptData } from "../../app/utils";

import "swiper/css";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "./Analysis.scss";
import Header from "../../components/Header/Header";

const Analysis = () => {
  const today = format(new Date(), "dd/MM/yyyy");
  const [userAttemptData, setUserAttemptData] = useState<
    GamePlayState.AttemptData[]
  >([]);
  const [quizId, setQuizId] = useState<number>(0);
  const [timeTaken, setTimeTaken] = useState<number>(0);
  const [currentStreak, setCurrentStreak] = useState<number>(1);

  useEffect(() => {
    const gameStateString = window.localStorage.getItem("game_state");
    if (gameStateString) {
      const { quizId, gameStatus, userAttemptData, date, timeTaken } =
        JSON.parse(gameStateString) as GamePlayState.Root;
      if (gameStatus === "PLAYING" || date !== today) goToHomescreen();
      setQuizId(quizId);
      setTimeTaken(timeTaken);
      setUserAttemptData(userAttemptData);
    } else {
      goToHomescreen();
    }
    const gameStatisticsString = window.localStorage.getItem("game_statistics");
    if (gameStatisticsString) {
      const gameStatistics = JSON.parse(gameStatisticsString) as GameStatistics;
      setCurrentStreak(gameStatistics.currentStreak);
    }
  }, []);

  const handleClickShare = () => {
    sendMoengageEvent("Entri Game Score Share");
    const shareText = generateEntriGameShareText(quizId, userAttemptData, currentStreak, timeTaken);
    shareAttemptData(shareText);
  };

  const goToHomescreen = () => {
    const origin = window.location.origin;
    window.location.replace(origin);
  };

  const getQuestionItem = (userAttemptLevel: GamePlayState.AttemptData) => {
    const { selectedOptions, question } = userAttemptLevel;
    return (
      <>
        {!isEmpty(selectedOptions) && (
          <div style={{ paddingTop: 4, paddingBottom: 20 }}>
            {getQuestionAttemptIndicator(selectedOptions)}
          </div>
        )}
        <EGCard>{question}</EGCard>
        <div className="answers-container" style={{ marginTop: 14 }}>
          {selectedOptions.map((option) => {
            return (
              <div
                key={option.id}
                className={clsx("option", {
                  selected: option.isSelected,
                  correct: option.isCorrect,
                  wrong: !option.isCorrect,
                })}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="container">
      <Header />
      <div style={{ height: "100%" }}>
        <Swiper
          className="swiper"
          spaceBetween={50}
          autoHeight
          slidesPerView={1}
          modules={[Pagination, Keyboard, Mousewheel]}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "bullets",
          }}
          mousewheel={{
            invert: false,
          }}
          keyboard={{
            enabled: true,
          }}
        >
          {userAttemptData.map((userAttemptLevel, index) => {
            return (
              <SwiperSlide key={index} style={{ paddingBottom: 30 }}>
                {getQuestionItem(userAttemptLevel)}
              </SwiperSlide>
            );
          })}
          <div className="swiper-pagination"></div>
        </Swiper>

        <div className="share-btn-container">
          <EGButton onClick={handleClickShare} style={{ marginTop: 22 }}>
            Share
          </EGButton>
        </div>
      </div>
    </div>
  );
};

export default Analysis;
