import { AxiosResponse } from "axios";
import { LeaderBoard } from "../../features/SpokenEnglish/model";
import httpService from "./httpService";

export function getLeaderboard(): Promise<
  AxiosResponse<{ data: LeaderBoard.Score[] }>
> {
  return httpService.get(`public-games/pastgames123/leaderboard/`);
}
export function postUserDetails(userDetails: {
  name: string;
  phone: string | number;
  score: number;
}): Promise<AxiosResponse<{ data: { message: string; rank: number } }>> {
  return httpService.post(
    `public-games/pastgames123/leaderboard/`,
    userDetails
  );
}
