export const LANGUAGES: {
    [key: string]: {
      text: string;
      textEn: string;
      direction: "ltr" | "rtl";
    };
  } = {
    ml: {
      text: "മലയാളം",
      textEn: "Malayalam",
      direction: "ltr",
    },
    hi: {
      text: "हिंदी",
      textEn: "Hindi",
      direction: "ltr",
    },
    ta: {
      text: "தமிழ்",
      textEn: "Tamil",
      direction: "ltr",
    },
    kn: {
      text: "ಕನ್ನಡ",
      textEn: "Kannada",
      direction: "ltr",
    },
    te: {
      text: "తెలుగు",
      textEn: "Telugu",
      direction: "ltr",
    },
    ur: {
      text: "اردو",
      textEn: "Urdu",
      direction: "rtl",
    },
    mr: {
      text: "मराठी",
      textEn: "Marathi",
      direction: "ltr",
    },
    bn: {
      text: "বাংলা",
      textEn: "Bengali",
      direction: "ltr",
    },
    gu: {
      text: "ગુજરાતી",
      textEn: "Gujarati",
      direction: "ltr",
    },
    or: {
      text: "ଓଡ଼ିଆ",
      textEn: "Odiya",
      direction: "ltr",
    },
  };