import { debounce } from "lodash";
import React, { useState } from "react";

type Props = {
  onSubmit: (name: string, phone: string) => void;
  isLoading: boolean;
};

const UserFrom: React.FC<Props> = ({ onSubmit, isLoading }) => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const handleOnSubmit = debounce(() => {
    onSubmit(name, phone);
  }, 300);

  return (
    <div className="user-form">
      <h3 className="title">Enter Details</h3>
      <form>
        <div className="form-group mb-sm-3">
          <input
            type="text"
            id="name"
            name="name"
            placeholder=" "
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            autoComplete="off"
          />
          <label className="floating-label">Name</label>
        </div>

        <div className="form-group mb-sm-3">
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder=" "
            pattern="[6789][0-9]\d{8,11}$"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            autoComplete="off"
          />
          <label className="floating-label">Phone Number</label>
        </div>
      </form>
      <div className="btn-holder">
        <button
          className="btn btn-raised"
          disabled={isLoading || !name || !/[6789][0-9]\d{8,11}$/.test(phone)}
          onClick={handleOnSubmit}
        >
          {isLoading ? (
            <div className="loader">
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : (
            "Play now"
          )}
        </button>
      </div>
    </div>
  );
};

export default UserFrom;
