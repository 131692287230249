import clsx from "clsx";
import { debounce } from "lodash";
import React, { useState } from "react";
import { SimpleQuiz } from "../../../types/SimpleQuiz";

type Props = {
  question: SimpleQuiz.Question;
  onQuestionClick: (choiceId: number, isCorrectAnswer: boolean) => void;
};

const Question: React.FC<Props> = ({ question, onQuestionClick }) => {
  const [selectedOption, setSelectedOption] = useState<number>();
  const handleChoiceClick = debounce(
    (choiceId: number, isCorrectAnswer: boolean) => {
      setSelectedOption(choiceId);
      onQuestionClick(choiceId, isCorrectAnswer);
    },
    400
  );
  return (
    <div className="question">
      <p className="question-statement">{question.questionStatement}</p>
      <div className="choices">
        {question.options.map((option) => (
          <div
            key={option.id}
            className={clsx(
              "option",
              selectedOption === option.id && "selected",
              selectedOption === option.id
                ? option.isCorrect
                  ? "correct"
                  : "wrong"
                : ""
            )}
            onClick={() => handleChoiceClick(option.id, option.isCorrect)}
          >
            <p className="option-statement">{option.choiceStatement}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
