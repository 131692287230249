import { format } from "date-fns";
import Quiz from "../model/Quiz";

const quizzes: Quiz.RootObject[] = [
    {
        date: '24/09/2022',
        id: 24,
        questions: [
            {
                id: 1,
                question: 'താഴെ നൽകിയിരിക്കുന്ന സംസ്ഥാനങ്ങളിൽ ഏതാണ് അറബിക്കടലുമായി അതിർത്തി പങ്കിടുന്നത്?',
                correctAnswers: [1, 2, 3, 6, 10],
            },
            {
                id: 2,
                question: 'താഴെ കൊടുത്തിരിക്കുന്ന സംസ്ഥാനങ്ങളിൽ നിന്ന് ഇന്ത്യയിലെ ഉയർന്ന മാനവിക വികസന സൂചികയുള്ള മികച്ച 4 സംസ്ഥാനങ്ങൾ കണ്ടെത്തുക?',
                correctAnswers: [2, 3, 6, 10],
            },
            {
                id: 3,
                question: 'താഴെ പറയുന്ന സംസ്ഥാനങ്ങളിൽ നിന്ന് ഏറ്റവും കൂടുതൽ കാപ്പി ഉത്പാദിപ്പിക്കുന്ന മൂന്ന് ദക്ഷിണേന്ത്യൻ സംസ്ഥാനങ്ങൾ കണ്ടെത്തുക?',
                correctAnswers: [2, 6, 10],
            },
            {
                id: 4,
                question: 'ഭവാനി നദി താഴെപ്പറയുന്നവയിൽ ഏത് സംസ്ഥാനത്തിലൂടെയാണ് ഒഴുകുന്നത്?',
                correctAnswers: [2, 6],
            },
            {
                id: 5,
                question: 'ഇന്ത്യയിൽ ഏറ്റവും കൂടുതൽ സാക്ഷരതാ നിരക്ക് ഉള്ള സംസ്ഥാനം?',
                correctAnswers: [6],
            },
        ],
        answerKeys: [
            {
                id: 1,
                label: 'ഗുജറാത്ത്',
                btnVariant: 'violet',
            },
            {
                id: 2,
                label: 'തമിഴ്‌നാട്',
                btnVariant: 'green',
            },
            {
                id: 3,
                label: 'മഹാരാഷ്ട്ര',
                btnVariant: 'blue',
            },
            {
                id: 4,
                label: 'രാജസ്ഥാൻ',
                btnVariant: 'yellow',
            },
            {
                id: 5,
                label: 'ആന്ധ്രാപ്രദേശ്',
                btnVariant: 'violet',
            },
            {
                id: 6,
                label: 'കേരളം',
                btnVariant: 'green',
            },
            {
                id: 7,
                label: 'ആസാം',
                btnVariant: 'blue',
            },
            {
                id: 8,
                label: 'ഒഡിഷ',
                btnVariant: 'yellow',
            },
            {
                id: 9,
                label: 'പശ്ചിമബംഗാൾ',
                btnVariant: 'violet',
            },
            {
                id: 10,
                label: 'കർണാടക',
                btnVariant: 'green',
            },
        ],
        targetAnswerId: 6,
    },
    {
        date: '25/09/2022',
        id: 25,
        questions: [
            {
                id: 1,
                question: 'താഴെ നൽകിയിരിക്കുന്ന നദികളിൽ ഏതാണ് ഉപദ്വീപ് നദികൾ?',
                correctAnswers: [1, 2, 4, 6, 8],
            },
            {
                id: 2,
                question: 'താഴെ നൽകിയിരിക്കുന്ന നദികളിൽ ഏതാണ് ബംഗാൾ ഉൾക്കടലിലേക്ക് ഒഴുകുന്നത്?',
                correctAnswers: [1, 2, 4, 6],
            },
            {
                id: 3,
                question: 'താഴെ നൽകിയിരിക്കുന്ന നദികളിൽ ഏതാണ് പശ്ചിമഘട്ടത്തിൽ നിന്ന് ഉത്ഭവിക്കുന്നത്?',
                correctAnswers: [1, 2, 6],
            },
            {
                id: 4,
                question: 'താഴെ കൊടുത്തിരിക്കുന്ന നദികളിൽ ഏതൊക്കെ നദികൾക്കിടയിലാണ് കൊല്ലേരു തടാകം സ്ഥിതി ചെയ്യുന്നത്?',
                correctAnswers: [1, 2],
            },
            {
                id: 5,
                question: 'താഴെ നൽകിയിരിക്കുന്ന നദികളിൽ നിന്ന് ഏത് നദിയാണ് ദക്ഷിണ ഗംഗ എന്നറിയപ്പെടുന്നത്?',
                correctAnswers: [1],
            },
        ],
        answerKeys: [
            {
                id: 1,
                label: 'ഗോദാവരി',
                btnVariant: 'violet',
            },
            {
                id: 2,
                label: 'കൃഷ്ണ',
                btnVariant: 'green',
            },
            {
                id: 3,
                label: 'ഭഗീരഥി',
                btnVariant: 'blue',
            },
            {
                id: 4,
                label: 'മഹാനദി',
                btnVariant: 'yellow',
            },
            {
                id: 5,
                label: 'അളകനന്ദ',
                btnVariant: 'violet',
            },
            {
                id: 6,
                label: 'കാവേരി',
                btnVariant: 'green',
            },
            {
                id: 7,
                label: 'ചെനാബ്',
                btnVariant: 'blue',
            },
            {
                id: 8,
                label: 'നർമദ',
                btnVariant: 'yellow',
            },
            {
                id: 9,
                label: 'സിന്ധു',
                btnVariant: 'violet',
            },
            {
                id: 10,
                label: 'ബീസ്',
                btnVariant: 'green',
            },
        ],
        targetAnswerId: 1,
    },
    {
        date: '26/09/2022',
        id: 26,
        questions: [
            {
                id: 1,
                question: 'താഴെ തന്നിരിക്കുന്നവയിൽ രക്തത്തിലെ ഘടകങ്ങൾ ഏവ?',
                correctAnswers: [1, 2, 4, 5, 6],
            },
            {
                id: 2,
                question: 'താഴെ തന്നിരിക്കുന്നവയിൽ രക്ത കോശങ്ങൾ ഏവ?',
                correctAnswers: [1, 4, 5, 6],
            },
            {
                id: 3,
                question: 'താഴെ തന്നിരിക്കുന്നവയിൽ ശ്വേത രക്താണുക്കൾ ഏവ?',
                correctAnswers: [1, 4, 6],
            },
            {
                id: 4,
                question: 'ഫാഗോസൈറ്റായി പ്രവർത്തിക്കാത്ത ശ്വേതരക്താണുക്കൾ?',
                correctAnswers: [4, 6],
            },
            {
                id: 5,
                question: 'ആന്റിബോഡി ഉൽപ്പാദിപ്പിക്കുന്ന ശ്വേതരക്താണു ഏത്?',
                correctAnswers: [4],
            },
        ],
        answerKeys: [
            {
                id: 1,
                label: 'മോണോസൈറ്റ്',
                btnVariant: 'violet',
            },
            {
                id: 2,
                label: 'പ്ലാസ്മ',
                btnVariant: 'green',
            },
            {
                id: 3,
                label: 'അക്വസ് ദ്രവം',
                btnVariant: 'blue',
            },
            {
                id: 4,
                label: 'B - ലിംഫോസൈറ്റ് ',
                btnVariant: 'yellow',
            },
            {
                id: 5,
                label: 'അരുണ രക്താണു',
                btnVariant: 'violet',
            },
            {
                id: 6,
                label: 'T - ലിംഫോസൈറ്റ്',
                btnVariant: 'green',
            },
            {
                id: 7,
                label: 'പ്ലൂറൽ ദ്രവം',
                btnVariant: 'blue',
            },
            {
                id: 8,
                label: 'പെരികാർഡിയൽ ദ്രവം',
                btnVariant: 'yellow',
            },
            {
                id: 9,
                label: 'വിട്രിയസ് ദ്രവം',
                btnVariant: 'violet',
            },
            {
                id: 10,
                label: 'സെറിബ്രോ സ്‌പൈനൽ ദ്രവം',
                btnVariant: 'green',
            },
        ],
        targetAnswerId: 4,
    },
    {
        date: '27/09/2022',
        id: 27,
        questions: [
            {
                id: 1,
                question: 'താഴെ തന്നിരിക്കുന്നവയിൽ കോശത്തിന്റെ ഭാഗങ്ങൾ ഏവ?',
                correctAnswers: [1, 4, 5, 8, 9],
            },
            {
                id: 2,
                question: 'മനുഷ്യകോശത്തിന്റെ ഭാഗമായുള്ളവ?',
                correctAnswers: [4, 5, 8, 9],
            },
            {
                id: 3,
                question: 'കോശദ്രവ്യത്തിൽ കാണപ്പെടുന്നവ?',
                correctAnswers: [4, 8, 9],
            },
            {
                id: 4,
                question: 'സസ്യകോശങ്ങളിൽ കാണപ്പെടാത്തത്?',
                correctAnswers: [8, 9],
            },
            {
                id: 5,
                question: 'കോശവിഭജനത്തിന് സഹായിക്കുന്ന കോശാംഗം?',
                correctAnswers: [9],
            },
        ],
        answerKeys: [
            {
                id: 1,
                label: 'കോശഭിത്തി',
                btnVariant: 'violet',
            },
            {
                id: 2,
                label: 'പ്ലൂറ',
                btnVariant: 'green',
            },
            {
                id: 3,
                label: 'നെഫ്‌റോൺ',
                btnVariant: 'blue',
            },
            {
                id: 4,
                label: 'ഫേനം',
                btnVariant: 'yellow',
            },
            {
                id: 5,
                label: 'കോശസ്തരം',
                btnVariant: 'violet',
            },
            {
                id: 6,
                label: 'ഐറിസ്',
                btnVariant: 'green',
            },
            {
                id: 7,
                label: 'പ്ലാസ്മ',
                btnVariant: 'blue',
            },
            {
                id: 8,
                label: 'ലൈസോസോം',
                btnVariant: 'yellow',
            },
            {
                id: 9,
                label: 'സെൻട്രോസോം',
                btnVariant: 'violet',
            },
            {
                id: 10,
                label: 'റെറ്റിന',
                btnVariant: 'green',
            },
        ],
        targetAnswerId: 9,
    },
    {
        date: '28/09/2022',
        id: 28,
        questions: [
            {
                id: 1,
                question: 'താഴെപ്പറയുന്നവയിൽ ഏതൊക്കെ ഓഫീസുകളിലേക്കാണ് ഇന്ത്യൻ രാഷ്ട്രപതി നിയമനം നടത്തുന്നത്?',
                correctAnswers: [1, 2, 3, 4, 5],
            },
            {
                id: 2,
                question: 'താഴെ നൽകിയിരിക്കുന്നവയിൽ ഏതൊക്കെയാണ് ഭരണഘടനാ സ്ഥാപനങ്ങൾ?',
                correctAnswers: [1, 2, 4, 5],
            },
            {
                id: 3,
                question: 'താഴെ നൽകിയിരിക്കുന്ന ഏതൊക്കെ ഓഫീസുകളിൽ ആണ് അവരുടെ പ്രവർത്തനത്തിലെ നിഷ്പക്ഷത ഉറപ്പുവരുത്തുന്നതിനായി സേവന കാലയളവിൽ കാലാവധിയുടെ സുരക്ഷ നൽകിയിട്ടുള്ളത്?',
                correctAnswers: [1, 2, 5],
            },
            {
                id: 4,
                question: 'താഴെപ്പറയുന്ന ഏതൊക്കെ ഓഫീസുകളുടെ സേവന വ്യവസ്ഥകളാണ്  രാഷ്ട്രപതി തീരുമാനിക്കുന്നത്?',
                correctAnswers: [1, 2],
            },
            {
                id: 5,
                question: 'ഭരണഘടനയുടെ ആർട്ടിക്കിൾ 324 പ്രകാരം, ഏത് ഓഫീസിനാണ് ഇന്ത്യയൊട്ടാകെ അധികാരവും നിയന്ത്രണവും നൽകിയിരിക്കുന്നത്?',
                correctAnswers: [1],
            },
        ],
        answerKeys: [
            {
                id: 1,
                label: 'തിരഞ്ഞെടുപ്പ് കമ്മീഷൻ',
                btnVariant: 'violet',
            },
            {
                id: 2,
                label: 'യൂണിയൻ പബ്ലിക് സർവീസ് കമ്മീഷൻ',
                btnVariant: 'green',
            },
            {
                id: 3,
                label: 'മുഖ്യ വിവരാവകാശ കമ്മീഷൻ',
                btnVariant: 'blue',
            },
            {
                id: 4,
                label: 'ഇന്ത്യയുടെ അറ്റോർണി ജനറൽ',
                btnVariant: 'yellow',
            },
            {
                id: 5,
                label: 'ഇന്ത്യയുടെ കൺട്രോളർ ആൻഡ് ഓഡിറ്റർ ജനറൽ',
                btnVariant: 'violet',
            },
            {
                id: 6,
                label: 'ദേശീയ വനിതാ കമ്മീഷൻ',
                btnVariant: 'green',
            },
            {
                id: 7,
                label: 'ടെലികോം റെഗുലേറ്ററി അതോറിറ്റി ഓഫ് ഇന്ത്യ',
                btnVariant: 'blue',
            },
            {
                id: 8,
                label: 'നീതി ആയോഗ്',
                btnVariant: 'yellow',
            },
            {
                id: 9,
                label: 'ലോകായുക്ത',
                btnVariant: 'violet',
            },
            {
                id: 10,
                label: 'സംസ്ഥാന വിവരാവകാശ കമ്മീഷൻ',
                btnVariant: 'green',
            },
        ],
        targetAnswerId: 1,
    },
    {
        date: '29/09/2022',
        id: 29,
        questions: [
            {
                id: 1,
                question: 'താഴെ കൊടുത്തിരിക്കുന്നവയിൽ ഏതാണ് യൂക്കറിയോട്ടുകൾ?',
                correctAnswers: [1, 2, 3, 4, 5],
            },
            {
                id: 2,
                question: 'താഴെ നൽകിയിരിക്കുന്നവയിൽ ഏതൊക്കെയാണ് മൾട്ടിസെല്ലുലാർ യൂക്കറിയോട്ടുകൾ?',
                correctAnswers: [2, 3, 4, 5],
            },
            {
                id: 3,
                question: 'താഴെ കൊടുത്തിരിക്കുന്നവയിൽ ഏതാണ് ഊർജം ലഭിക്കുന്നതിന് ഫോട്ടോസിന്തസിസ് നടത്തുന്നത്?',
                correctAnswers: [2, 3, 5],
            },
            {
                id: 4,
                question: 'താഴെ കൊടുത്തിരിക്കുന്നവയിൽ ഏതാണ് പരാദജീവികളല്ലാത്ത സസ്യങ്ങൾ പോലെയുള്ള ജീവികൾ?',
                correctAnswers: [2, 3],
            },
            {
                id: 5,
                question: 'താഴെ നൽകിയിരിക്കുന്നവയിൽ ആൽഗെയുടെയും ഫംഗസിന്റെയും സഹജീവി ബന്ധത്താൽ രൂപപ്പെടുന്നത് ഏതാണ്?',
                correctAnswers: [3],
            },
        ],
        answerKeys: [
            {
                id: 1,
                label: 'പ്രോട്ടോസോവ',
                btnVariant: 'violet',
            },
            {
                id: 2,
                label: 'ആൽഗെ',
                btnVariant: 'green',
            },
            {
                id: 3,
                label: 'ലൈക്കൺ',
                btnVariant: 'blue',
            },
            {
                id: 4,
                label: 'ഫംഗൈ',
                btnVariant: 'yellow',
            },
            {
                id: 5,
                label: 'സസ്യങ്ങൾ',
                btnVariant: 'violet',
            },
            {
                id: 6,
                label: 'ബാക്ടീരിയ',
                btnVariant: 'green',
            },
            {
                id: 7,
                label: 'ആർക്കിയ',
                btnVariant: 'blue',
            },
            {
                id: 8,
                label: 'പരമീസിയം',
                btnVariant: 'yellow',
            },
            {
                id: 9,
                label: 'യൂഗ്ലിന',
                btnVariant: 'violet',
            },
            {
                id: 10,
                label: 'അമീബ',
                btnVariant: 'green',
            },
        ],
        targetAnswerId: 3,
    },
    {
        date: '30/09/2022',
        id: 30,
        questions: [
            {
                id: 1,
                question: 'താഴെപ്പറയുന്നവയിൽ ഏതൊക്കെ ജലാശയങ്ങളെയാണ് അന്തർദേശീയ നോർത്ത് സൗത്ത് ട്രാൻസിറ്റ് കോറിഡോർ നെ ബന്ധിപ്പിച്ചിരിക്കുന്നത്?',
                correctAnswers: [1, 2, 3, 4, 5],
            },
            {
                id: 2,
                question: 'അന്താരാഷ്ട്ര നോർത്ത് സൗത്ത് ട്രാൻസിറ്റ് കോറിഡോറിനെ ബന്ധിപ്പിക്കുന്ന കടലുകളിൽ താഴെ പറയുന്നവയിൽ ഏതൊക്കെയാണ്  ലാഡ്ലോക്ട് അല്ലാത്തത്?',
                correctAnswers: [2, 3, 4, 5],
            },
            {
                id: 3,
                question: 'വടക്കൻ അറ്റ്ലാന്റിക് സമുദ്രത്തെയും ഇന്ത്യൻ മഹാസമുദ്രത്തെയും ബന്ധിപ്പിക്കുന്ന ഏറ്റവും ചെറിയ കടൽ പാതയിൽ വരുന്ന കടലുകൾ ഏതൊക്കെയാണ്?',
                correctAnswers: [3,4,5],
            },
            {
                id: 4,
                question: 'താഴെ കൊടുത്തിരിക്കുന്ന ഏതൊക്കെ കടലുകളാണ് ചെങ്കടലുമായി ബന്ധിപ്പിച്ചിരിക്കുന്നത്?',
                correctAnswers: [4,5],
            },
            {
                id: 5,
                question: 'താഴെ കൊടുത്തിരിക്കുന്ന കടലുകളിൽ ഏതാണ്  ആഫ്രിക്കയെയും ഏഷ്യയെയും യൂറോപ്പിനെയും തമ്മിൽ ബന്ധിപ്പിക്കുന്നത്?',
                correctAnswers: [4],
            },
        ],
        answerKeys: [
            {
                id: 1,
                label: 'കാസ്പിയൻ കടൽ',
                btnVariant: 'violet',
            },
            {
                id: 2,
                label: 'ബാൾട്ടിക് കടൽ',
                btnVariant: 'green',
            },
            {
                id: 3,
                label: 'വടക്കൻ കടൽ',
                btnVariant: 'blue',
            },
            {
                id: 4,
                label: 'മെഡിറ്ററേനിയൻ കടൽ',
                btnVariant: 'yellow',
            },
            {
                id: 5,
                label: 'അറബിക്കടൽ',
                btnVariant: 'violet',
            },
            {
                id: 6,
                label: 'ബംഗാൾ ഉൾക്കടൽ',
                btnVariant: 'green',
            },
            {
                id: 7,
                label: 'തെക്കൻ ചൈനാ കടൽ',
                btnVariant: 'blue',
            },
            {
                id: 8,
                label: 'കരിങ്കടല്',
                btnVariant: 'yellow',
            },
            {
                id: 9,
                label: 'ബെറിംഗ് കടൽ',
                btnVariant: 'violet',
            },
            {
                id: 10,
                label: 'കരീബിയൻ കടൽ',
                btnVariant: 'green',
            },
        ],
        targetAnswerId: 4,
    },
    {
        date: '01/10/2022',
        id: 31,
        questions: [
            {
                id: 1,
                question: 'താഴെ കൊടുത്തിരിക്കുന്ന ക്ഷേത്രങ്ങളിൽ ഏതൊക്കെയാണ് യുനെസ്കോയുടെ ലോക പൈതൃക സൈറ്റുകൾ?',
                correctAnswers: [1, 2, 3, 4, 5],
            },
            {
                id: 2,
                question: 'താഴെ നൽകിയിരിക്കുന്ന ക്ഷേത്രങ്ങളിൽ ഏതൊക്കെയാണ് ശിവക്ഷേത്രങ്ങൾ?',
                correctAnswers: [2, 3, 4, 5],
            },
            {
                id: 3,
                question: 'താഴെ നൽകിയിരിക്കുന്ന ക്ഷേത്രങ്ങളിൽ ഏതൊക്കെയാണ് ദക്ഷിണേന്ത്യയിൽ സ്ഥിതി ചെയ്യുന്നത്?',
                correctAnswers: [3,4,5],
            },
            {
                id: 4,
                question: 'താഴെ കൊടുത്തിരിക്കുന്നവയിൽ ചോള രാജവംശം നിർമ്മിച്ച ക്ഷേത്രങ്ങൾ ഏതൊക്കെയാണ്?',
                correctAnswers: [4,5],
            },
            {
                id: 5,
                question: 'താഴെ കൊടുത്തിരിക്കുന്നവയിൽ കാവേരി നദിയുടെ തീരത്ത് സ്ഥിതി ചെയ്യുന്ന  ഏത്  ക്ഷേത്രമാണ്  രാജരാജ ചോളൻ 1 നിർമ്മിച്ചത്?',
                correctAnswers: [4],
            },
        ],
        answerKeys: [
            {
                id: 1,
                label: 'കൊണാർക്കിലെ സൂര്യക്ഷേത്രം',
                btnVariant: 'violet',
            },
            {
                id: 2,
                label: 'കാന്ദാരിയ മഹാദേവ ക്ഷേത്രം',
                btnVariant: 'green',
            },
            {
                id: 3,
                label: 'മഹാബലിപുരത്തെ തീര ക്ഷേത്രം',
                btnVariant: 'blue',
            },
            {
                id: 4,
                label: 'ബൃഹദേശ്വര ക്ഷേത്രം',
                btnVariant: 'yellow',
            },
            {
                id: 5,
                label: 'ഗംഗൈകൊണ്ടചോളപുരം    ക്ഷേത്രം',
                btnVariant: 'violet',
            },
            {
                id: 6,
                label: 'ബദ്രിനാഥ് ക്ഷേത്രം',
                btnVariant: 'green',
            },
            {
                id: 7,
                label: 'വിത്തൽ ക്ഷേത്രം',
                btnVariant: 'blue',
            },
            {
                id: 8,
                label: 'ദിൽവാര ജൈന ക്ഷേത്രം',
                btnVariant: 'yellow',
            },
            {
                id: 9,
                label: 'ജഗന്നാഥ ക്ഷേത്രം',
                btnVariant: 'violet',
            },
            {
                id: 10,
                label: 'ദശാവതാര ക്ഷേത്രം',
                btnVariant: 'green',
            },
        ],
        targetAnswerId: 4,
    },
]

// A mock function to mimic actual API
export function getTodaysQuiz() {
    return new Promise<{ data: Quiz.RootObject }>((resolve, reject) => {
        return setTimeout(() => {
            const today = format(new Date(), 'dd/MM/yyyy')
            const quiz = quizzes.find((questionSet) => questionSet.date === today)

            if (quiz) {
                resolve({ data: quiz })
            } else {
                reject({ error: 'No quiz for today, comeback later' })
            }
        }, 0)
    }

    );
}
