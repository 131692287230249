import React from "react";
import GiftYIcon from "../../../assets/images/gift-y.png";
import GiftGIcon from "../../../assets/images/gift-g.png";
import HeartRIcon from "../../../assets/images/heart-r.png";
import HeartYIcon from "../../../assets/images/heart-y.png";
import SpringYIcon from "../../../assets/images/spring-y.png";
import SpringWIcon from "../../../assets/images/spring-w.png";
import { shareCampusQuiz } from "../../../app/utils";

type Props = {};

const EndScreen = (props: Props) => {
  return (
    <div className="end-screen">
      <div className="top-container">
        <h3 className="title">HURRAY!!!</h3>
        <p className="greeting">Thank you for completing the quiz</p>
        <img
          src={HeartRIcon}
          alt="floating"
          className="floating-image heart-r"
        />
        <img src={GiftYIcon} alt="floating" className="floating-image gift-y" />
        <img
          src={SpringWIcon}
          alt="floating"
          className="floating-image spring-w"
        />
        <img
          src={HeartYIcon}
          alt="floating"
          className="floating-image heart-y"
        />
        <img src={GiftGIcon} alt="floating" className="floating-image gift-g" />
        <img
          src={SpringYIcon}
          alt="floating"
          className="floating-image spring-y"
        />
      </div>
      <div className="bottom-container">
        <p className="share-text">TAp HERE TO SHARE WITH YOUR LOVED ONES</p>
        <button className="btn btn-raised" onClick={() => shareCampusQuiz()}>
          Share now
        </button>
      </div>
    </div>
  );
};

export default EndScreen;
