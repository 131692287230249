import React, { useMemo } from "react";
import GameScore from "./components/GameScore";
import Level from "./components/Level";
import { QuestionAttemptData } from "./Index";
import { getScoreArray } from "./utils";

interface QuizScreenProps {
    timeRemaining: number;
    attemptData: QuestionAttemptData[];
    onSelectOption: (questionIndex: number, optionIndex: number) => void;
}

const QuizScreen: React.FC<QuizScreenProps> = ({
    timeRemaining,
    attemptData,
    onSelectOption,
}) => {

    const activeQuestionIndex = useMemo(
        () => attemptData.findIndex((question) => question.selectedOption === undefined),
        [attemptData]);

    const scores = useMemo(() => getScoreArray(attemptData), [attemptData])

    if (activeQuestionIndex !== 0 && !activeQuestionIndex) {
        return <h2>No question</h2>;
    }

    return (
        <div className='quiz'>
            <div className="wc-game-stats">
                <GameScore scores={scores} />
                {timeRemaining + 's'}
            </div>
            <Level
                question={attemptData[activeQuestionIndex]}
                onSelectOption={(optionIndex) => onSelectOption(activeQuestionIndex, optionIndex)}
            />
        </div>
    )
}

export default QuizScreen;