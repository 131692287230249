import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Keyboard, Mousewheel } from "swiper";
import EGCard from "../../components/Card/Card";
import { QuestionAttemptData, WCGameStats } from "./Index";
import { useNavigate } from "react-router-dom";
import EGSelectableButton from "../../components/SelectableButton/SelectableButton";

import "swiper/css";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "../Analysis/Analysis.scss";

const WCAnswers = () => {
    const navigate = useNavigate();
    const today = format(new Date(), "dd/MM/yyyy");
    const [userAttemptData, setUserAttemptData] = useState<WCGameStats>();

    useEffect(() => {
        const gameStateString = window.localStorage.getItem("wc_game_stats");
        if (gameStateString) {
            const WCGameStats = JSON.parse(gameStateString) as WCGameStats;
            console.log({ WCGameStats })
            if (WCGameStats.status !== "COMPLETED" || WCGameStats.date !== today) goToHomescreen();
            setUserAttemptData(WCGameStats);
        } else {
            goToHomescreen();
        }
    }, []);

    const handleClickShare = () => {
        // sendMoengageEvent("Entri Game Score Share");
        // const shareText = generateEntriGameShareText(quizId, userAttemptData, currentStreak, timeTaken);
        // shareAttemptData(shareText);
    };

    const goToHomescreen = () => {
        navigate('/fifa/')
    };

    const getQuestionItem = (questionAttemptData: QuestionAttemptData) => {
        const { selectedOption, options, description, id } = questionAttemptData;
        return (
            <>
                <EGCard>{description}</EGCard>
                <div className="wc-options-container" style={{ marginTop: 14 }}>
                    {options.map((option, index) => {
                        return (
                            <div
                                key={index}
                                className={'wc-option'}
                            >
                                <EGSelectableButton
                                    variant={selectedOption === index || option.isCorrect ?
                                        option.isCorrect ? 'green' : 'red'
                                        : 'blue'}
                                    style={{
                                        height: '100%',
                                        width: '100%'
                                    }}
                                    selected={selectedOption === index || option.isCorrect}
                                    onClick={() => { }}
                                >
                                    <span>{option.label}</span>
                                </EGSelectableButton>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    return (
        <div className="world-cup-game">
            <div className="wc-header">
                <p className="small">World Cup 2022</p>
                <p className="large">Daily Quiz</p>
            </div>
            <div className="wc-content">
                <div style={{ height: "100%" }}>
                    <Swiper
                        className="swiper"
                        spaceBetween={50}
                        autoHeight
                        slidesPerView={1}
                        modules={[Pagination, Keyboard, Mousewheel]}
                        pagination={{
                            clickable: true,
                            el: ".swiper-pagination",
                            type: "bullets",
                        }}
                        mousewheel={{
                            invert: false,
                        }}
                        keyboard={{
                            enabled: true,
                        }}
                    >
                        {userAttemptData?.attemptData.map((userAttemptLevel, index) => {
                            return (
                                <SwiperSlide key={index} style={{ paddingBottom: 30 }}>
                                    {getQuestionItem(userAttemptLevel)}
                                </SwiperSlide>
                            );
                        })}
                        <div className="swiper-pagination"></div>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default WCAnswers;