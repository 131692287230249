import { format } from "date-fns";
import { QuestionAttemptData, WCGameStats } from "./Index";

export const getScoreArray = (attemptData: QuestionAttemptData[]) => {
    return attemptData.map((question) => {
        switch (question.selectedOption) {
            case undefined:
                return undefined;
            case 0:
            default:
                if (question.options[question.selectedOption].isCorrect) {
                    return true;
                }
                return false;
        }
    })
}

export const generateWCShareText = (
    attemptData: QuestionAttemptData[],
    remainingTime: number,
) => {

    let textToShare = `Daily World Cup Quiz\n\n`;
    const scores = getScoreArray(attemptData);
    let goalCount = 0;
    scores.forEach((score) => {
        if (score) {
            textToShare += '⚽';
            goalCount++;
        } else {
            textToShare += '❌';
        }
    });
    
    const score = calculateScore(attemptData, remainingTime);

    textToShare += `\n\nI scored ${score}🔥`;
    textToShare += "\n\nCan you beat this? 😏";
    textToShare += "\nhttps://playground.entri.app/fifa/";

    return textToShare;
}

export const getScore = () => {

    const gameStatsFromStorage = localStorage.getItem('wc_game_stats');
    if (gameStatsFromStorage) {
        const gameStatsObject = JSON.parse(gameStatsFromStorage) as WCGameStats;
        if (gameStatsObject.date === format(new Date(), 'dd/MM/yyyy')) {

            const scores = getScoreArray(gameStatsObject.attemptData);
            const goalCount = scores.reduce((total, cur) => {
                return cur ? total + 1 : total;
            }, 0);

            const scoreForTime = Math.trunc(gameStatsObject.remainingTime / 4) +
                (gameStatsObject.remainingTime % 4 > 1 ? 1 : 0)

            const score = (goalCount * 10) + (scoreForTime);
            return score;
        }
    }
    return 0;
}

export const getWCGameStats = () => {
    const gameStatsFromStorage = localStorage.getItem('wc_game_stats');
    if (gameStatsFromStorage) {
        const gameStatsObject = JSON.parse(gameStatsFromStorage) as WCGameStats;
        if (gameStatsObject.date === format(new Date(), 'dd/MM/yyyy')) {
            return gameStatsObject;
        }
    }
    return null;
}

const calculateScore = (
    attemptData: QuestionAttemptData[],
    remainingTime: number
) => {
    const scores = getScoreArray(attemptData);
    const goalCount = scores.reduce((total, cur) => {
        return cur ? total + 1 : total;
    }, 0);

    const scoreForTime = Math.trunc(remainingTime / 4) +
        (remainingTime % 4 > 1 ? 1 : 0)

    const score = (goalCount * 10) + (scoreForTime);
    return score;
}