import clsx from "clsx";
import { Team } from "../constants";

interface GameScoreProps {
    scores: (boolean | undefined)[];
    fontSize?: number;
}

const GameScore: React.FC<GameScoreProps> = ({
    scores,
    fontSize = 16
}) => {
    return (
        <div
            className="scores-container"
            style={{ fontSize }}
        >
            {scores.map((score, index) => {
                return <div
                    key={`score-element-${index}`}
                    className={clsx("score-element", {
                        "correct": score,
                        "wrong": score === false,
                        'inactive': score === undefined,
                    })}
                    style={{

                    }}
                />
            })}

        </div>
    )
}

export default GameScore;