import React from "react";
import WonderlaBg from "../../../assets/images/wonderla.png";
import ValentineBg from "../../../assets/images/valentine.svg";
import OvalBg from "../../../assets/images/homescreen_oval.png";

type Props = {
  onPlayNow: () => void;
};

const StartScreen: React.FC<Props> = ({ onPlayNow }) => {
  return (
    <div className="start-screen">
      <h1 className="title">
        Entri’s <br /> Valentines Day Quiz{" "}
      </h1>
      <div className="img-container">
        <img src={ValentineBg} alt="entri valentines day" />
      </div>
      <div className="gift">
        <p className="greeting-text">
          This Valentine's Day, stand a chance to
          <br />
          <span>win 5 free tickets to Wonderla, Kochi.</span>
        </p>
        <div className="greeting-card">
          <img src={WonderlaBg} alt="wonderla gift card" />
        </div>
        <img src={OvalBg} className="oval" alt="home screen oval" />
      </div>
      <div className="btn-holder">
        <button className="btn btn-raised" onClick={onPlayNow}>
          Play now
        </button>
      </div>
    </div>
  );
};

export default StartScreen;
