import React from "react";
import { isEmpty } from "../../../app/utils";

type Props = {
  position: "bronze" | "silver" | "gold";
  name: string;
  score: string | number;
  rank: number;
  displayPicture: string;
};

const Leader: React.FC<Props> = ({
  position,
  score,
  name,
  rank,
  displayPicture,
}) => {
  return (
    <div className={`leader ${position}`}>
      <div className="avatar">
        {displayPicture ? (
          <img src={displayPicture} alt="avatar" />
        ) : (
          name.charAt(0)
        )}
        <div className={`badge ${position}`}>
          <div className="inner">{rank}</div>
        </div>
      </div>
      <div className="text-container">
        <h6 className="name">{name}</h6>
        <span className={!isEmpty(score) ? "score" : "text-not-attempted"}>
          {!isEmpty(score) ? score : "Not attempted"}
        </span>
      </div>
    </div>
  );
};

export default Leader;
