import { AxiosResponse } from "axios";
import { WCLeaderBoard } from "../../features/WorldCupQuiz/leaderboardSlice";
import { questions } from "../../features/WorldCupQuiz/wcQuestions";
import httpService from "./httpService";
import { sha256 } from "js-sha256";

// A mock function to mimic actual API
export function getRandomQuestions(questionCount: number) {
    var result = new Array(questionCount),
        len = questions.length,
        taken = new Array(len);
    if (questionCount > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (questionCount--) {
        var x = Math.floor(Math.random() * len);
        result[questionCount] = questions[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

export function getWCLeaderboard(): Promise<
    AxiosResponse<{ data: WCLeaderBoard.Score[] }>
> {
    return httpService.get(`public-games/wcquiz2022/leaderboard/`);
}

export function postWCUserDetails(userDetails: {
    name: string;
    phone: string | number;
    language: string;
    score: number;
}): Promise<AxiosResponse<{ data: { message: string; rank: number } }>> {
    const { name, phone, score } = userDetails;
    const hash = sha256(name + phone + score)
    return httpService.post(
        `public-games/wcquiz2022/leaderboard/`,
        { ...userDetails, secretKey: hash }
    );
}
