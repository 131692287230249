import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { gameRetryEvent } from "../events";
import { selectScore } from "../spokenEnglishSlice";

type GaveOverScreenProps = {
  onStart: () => void;
  gameOverReason: "COMPLETED" | "GAMEOVER";
};

const GameOverScreen: React.FC<GaveOverScreenProps> = ({
  onStart,
  gameOverReason,
}) => {
  const { score } = useSelector(selectScore);
  const handleOnRetry = () => {
    if (gameOverReason === "GAMEOVER")
      gameRetryEvent.call({
        source: "Web",
        attemptCount: 1,
      });
    onStart();
  };
  return (
    <React.Fragment>
      <div className="game-over-screen">
        <div
          className={clsx(
            "circle",
            gameOverReason === "COMPLETED" && "game-finished",
            gameOverReason === "GAMEOVER" && "game-over"
          )}
        >
          {gameOverReason === "COMPLETED" ? (
            <h2 className="game-over">
              Game
              <br />
              <span>Finished</span>
            </h2>
          ) : (
            <h2 className="game-over">
              Game
              <br />
              Over
            </h2>
          )}

          <h3>Your score is {score}</h3>
        </div>
      </div>
      {/* <div className="btn-container">
        <Link to="./leaderboard">
          <button className="btn-spoken scores">Scores</button>
        </Link>
        <button className="btn-spoken start" onClick={handleOnRetry}>
          {gameOverReason === "GAMEOVER" ? "retry" : "play"}
        </button>
      </div> */}
    </React.Fragment>
  );
};

export default GameOverScreen;
