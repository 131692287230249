import { shuffle } from "lodash";
import { LeaderBoard } from "./model";

export interface VerbDict {
  verb: string;
  past: string;
  question: string[];
  options: string[];
}

export interface Verb {
  verb: string;
  past: string;
  isCorrect?: boolean;
  isMovedAndWrong?: boolean;
}

export interface FillTask {
  question: string[];
  options: string[];
}

export type Challenge = {
  category: "MATCH" | "FILL";
  item: Verb[] | FillTask;
};

export interface GameChallenge {
  category: "MATCH" | "FILL";
  item: { verbs: Verb[]; pastTense: Verb[] } | FillTask;
}

export type DailyChallenge = GameChallenge[];

export const challenge: Array<Challenge[]> = [
  [
    {
      category: "FILL",
      item: {
        question: ["Last week, they", "to school."],
        options: ["went", "goed", "goted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "a book."],
        options: ["read", "readed", "red"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he"],
        options: ["slept", "sleeped", "slaped"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "her something."],
        options: ["told", "telled", "tall"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last class, she", "a question."],
        options: ["asked", "ast", "ask"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, they", "soccer."],
        options: ["played", "pladed", "ployed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she"],
        options: ["smiled", "smole", "smild"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, she", "the window."],
        options: ["opened", "opend", "opin"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "go", past: "went" },
        { verb: "read", past: "read" },
        { verb: "sleep", past: "slept" },
        { verb: "tell", past: "told" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "ask", past: "asked" },
        { verb: "play", past: "played" },
        { verb: "smile", past: "smiled" },
        { verb: "open", past: "opened" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Last winter, the birds"],
        options: ["flew", "flyed", "flied"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, he"],
        options: ["spoke", "speaked", "spead"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Before class, she", "down."],
        options: ["sat", "sitted", "seat"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he"],
        options: ["swam", "swimmed", "swimed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, they", "TV."],
        options: ["watched", "watch", "wutch"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "a car."],
        options: ["pushed", "pused", "past"],
      },
    },
    {
      category: "FILL",
      item: { question: ["Earlier, she"], options: ["cried", "cryed", "coy"] },
    },
    {
      category: "FILL",
      item: {
        question: ["Last week, he", "a package."],
        options: ["delivered", "deliver", "dalivar"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "fly", past: "flew" },
        { verb: "speak", past: "spoke" },
        { verb: "sit", past: "sat" },
        { verb: "swim", past: "swam" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "watch", past: "watched" },
        { verb: "push", past: "pushed" },
        { verb: "cry", past: "cried" },
        { verb: "deliver", past: "delivered" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Before six, she", "up."],
        options: ["woke", "waked", "whaked"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, she", "a song."],
        options: ["sang", "singed", "seng"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["After running, she", "water."],
        options: ["drank", "drinked", "drinkt"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he", "her a gift."],
        options: ["gave", "gived", "grived"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier today, he", "a noise."],
        options: ["heard", "heared", "hard"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, I", "my phone."],
        options: ["charged", "churge", "charge"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last week, they"],
        options: ["exercised", "exercise", "exercose"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, I", "a button."],
        options: ["pressed", "priss", "pross"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "wake", past: "woke" },
        { verb: "sing", past: "sang" },
        { verb: "drink", past: "drank" },
        { verb: "give", past: "gave" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "hear", past: "heard" },
        { verb: "charge", past: "charged" },
        { verb: "exercise", past: "exercised" },
        { verb: "press", past: "pressed" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Last week, she", "pizza."],
        options: ["ate", "eated", "eatted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier today, she"],
        options: ["ran", "runed", "runned"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, she"],
        options: ["wrote", "writed", "wrate"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "an elephant."],
        options: ["saw", "seed", "sed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, I", "a glass."],
        options: ["filled", "fell", "fill"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he"],
        options: ["waved", "wove", "wavved"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, he", "a bag."],
        options: ["carried", "carryed", "curry"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, she", "hello."],
        options: ["said", "sed", "sayed"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "eat", past: "ate" },
        { verb: "run", past: "ran" },
        { verb: "write", past: "wrote" },
        { verb: "see", past: "saw" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "fill", past: "filled" },
        { verb: "wave", past: "waved" },
        { verb: "carry", past: "carried" },
        { verb: "say", past: "said" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Yesterday, they", "happy."],
        options: ["were", "was", "be"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, my phone"],
        options: ["rang", "ringed", "ringged"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last year, she", "a prize."],
        options: ["won", "wined", "winned"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last week, she", "maths."],
        options: ["learned", "learn", "learnd"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "his phone."],
        options: ["used", "use", "uose"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, he", "weights."],
        options: ["lifted", "loft", "lofted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, she", "guests."],
        options: ["greeted", "gret", "grot"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, she"],
        options: ["coughed", "coff", "caft"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "they are", past: "were" },
        { verb: "ring", past: "rang" },
        { verb: "win", past: "won" },
        { verb: "learn", past: "learned" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "use", past: "used" },
        { verb: "lift", past: "lifted" },
        { verb: "greet", past: "greeted" },
        { verb: "cough", past: "coughed" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "bored."],
        options: ["felt", "feeled", "fold"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, he", "on a hat"],
        options: ["put", "putted", "pot"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A year ago, they", "hands."],
        options: ["shook", "shaked", "shock"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, she", "on a light."],
        options: ["turned", "turn", "turd"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, she", "a picture."],
        options: ["painted", "point", "punt"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, they"],
        options: ["hugged", "hug", "hot"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, the bridge"],
        options: ["collapsed", "cullpase", "callapse"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last year, a bee", "me."],
        options: ["stung", "stingered", "stunged"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "she feels", past: "felt" },
        { verb: "put", past: "put" },
        { verb: "shake", past: "shook" },
        { verb: "turn", past: "turned" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "paint", past: "painted" },
        { verb: "hug", past: "hugged" },
        { verb: "collapse", past: "collapsed" },
        { verb: "sting", past: "stung" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "paper."],
        options: ["cut", "cat", "cutted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, she", "the table."],
        options: ["wiped", "wupe", "woped"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he", "away a bottle."],
        options: ["threw", "throwed", "thrawn"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, the clothes"],
        options: ["dried", "dreed", "dryed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Before running, I", "my laces."],
        options: ["tied", "tyed", "tee"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, he"],
        options: ["whispered", "whispor", "whisperd"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last year, profits"],
        options: ["decreased", "docreased", "dicreased"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, the water"],
        options: ["boiled", "boil", "bail"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "cut", past: "cut" },
        { verb: "wipe", past: "wiped" },
        { verb: "throw", past: "threw" },
        { verb: "dry", past: "dried" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "tie", past: "tied" },
        { verb: "whisper", past: "whispered" },
        { verb: "decrease", past: "decreased" },
        { verb: "boil", past: "boiled" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "coffee."],
        options: ["made", "maked", "mike"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A month ago, the tree"],
        options: ["fell", "falled", "fill"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Two weeks ago, they"],
        options: ["fought", "fighted", "fited"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, they", "for a bus."],
        options: ["waited", "waided", "waitted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, ducklings", "mother."],
        options: ["followed", "fullow", "fallow"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, I", "the door."],
        options: ["locked", "luck", "lick"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, she"],
        options: ["concentrated", "concentreet", "concate"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "money."],
        options: ["withdrew", "withdrawed", "withdrain"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "make", past: "made" },
        { verb: "fall", past: "fell" },
        { verb: "fight", past: "fought" },
        { verb: "wait", past: "waited" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "follow", past: "followed" },
        { verb: "lock", past: "locked" },
        { verb: "concentrate", past: "concentrated" },
        { verb: "withdraw", past: "withdrew" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Last weekend, she", "apples."],
        options: ["sold", "selled", "sail"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, they"],
        options: ["met", "meeted", "metted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, I", "a candle."],
        options: ["lighted", "let", "lited"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "a ball."],
        options: ["dropped", "dropt", "drup"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last week, he", "her."],
        options: ["called", "cull", "coal"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, he", "the door."],
        options: ["shut", "shutted", "shuted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last year, they", "a door."],
        options: ["guarded", "guard", "goard"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, she"],
        options: ["sneezed", "snooze", "snaze"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "sell", past: "sold" },
        { verb: "meet", past: "met" },
        { verb: "light", past: "lighted" },
        { verb: "drop", past: "dropped" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "call", past: "called" },
        { verb: "shut", past: "shut" },
        { verb: "guard", past: "guarded" },
        { verb: "sneeze", past: "sneezed" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Last year, he", "his key."],
        options: ["forgot", "forgetted", "furget"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "down."],
        options: ["lay", "lain", "ley"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A month ago, he", "apples."],
        options: ["picked", "puck", "pack"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, they"],
        options: ["studied", "studyed", "stody"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, I", "the mouse."],
        options: ["clicked", "cluck", "cloak"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last week, she"],
        options: ["skated", "skite", "skateed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, her screen"],
        options: ["cracked", "crick", "croaked"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, he", "money."],
        options: ["refused", "refase", "refusd"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "forget", past: "forgot" },
        { verb: "lie", past: "lay" },
        { verb: "pick", past: "picked" },
        { verb: "study", past: "studied" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "click", past: "clicked" },
        { verb: "skate", past: "skated" },
        { verb: "crack", past: "cracked" },
        { verb: "refuse", past: "refused" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Last winter, she", "a coat."],
        options: ["wore", "weared", "woar"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, he", "his car."],
        options: ["crashed", "crush", "cresh"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, I", "some money."],
        options: ["paid", "payed", "puy"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, he"],
        options: ["relaxed", "relft", "ralax"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he"],
        options: ["left", "leaved", "laved"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, I", "my name."],
        options: ["signed", "sign", "sinned"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, she"],
        options: ["screamed", "screem", "screamd"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, they"],
        options: ["debated", "deboted", "debt"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "wear", past: "wore" },
        { verb: "crash", past: "crashed" },
        { verb: "pay", past: "paid" },
        { verb: "relax", past: "relaxed" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "leave", past: "left" },
        { verb: "sign", past: "signed" },
        { verb: "scream", past: "screamed" },
        { verb: "debate", past: "debated" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "a baby."],
        options: ["held", "holded", "holt"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, he", "a motorbike."],
        options: ["rode", "rided", "rude"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, I"],
        options: ["typed", "tiped", "tiept"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Two weeks ago, he", "a test."],
        options: ["failed", "foil", "fald"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "her grandparents."],
        options: ["visited", "vistd", "vosot"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, the plant"],
        options: ["died", "ded", "day"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, they", "the price."],
        options: ["discounted", "discant", "disct"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "a phone."],
        options: ["repaired", "report", "repaird"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "hold", past: "held" },
        { verb: "ride", past: "rode" },
        { verb: "type", past: "typed" },
        { verb: "fail", past: "failed" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "visit", past: "visited" },
        { verb: "die", past: "died" },
        { verb: "discount", past: "discounted" },
        { verb: "repair", past: "repaired" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Last week, the plant"],
        options: ["grew", "growed", "growt"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he", "a wallet."],
        options: ["stole", "stealed", "stealled"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, they", "at each other."],
        options: ["looked", "loct", "looded"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, she", "her hair."],
        options: ["brushed", "brust", "brused"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "over a puddle."],
        options: ["jumped", "jomp", "jumed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, she", "reading a book."],
        options: ["enjoyed", "enjoied", "enjod"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, she", "clothes."],
        options: ["designed", "dessined", "dasign"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, the train"],
        options: ["arrived", "arrove", "arrict"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "grow", past: "grew" },
        { verb: "steal", past: "stole" },
        { verb: "look", past: "looked" },
        { verb: "brush", past: "brushed" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "jump", past: "jumped" },
        { verb: "enjoy", past: "enjoyed" },
        { verb: "design", past: "designed" },
        { verb: "arrive", past: "arrived" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["A moment ago, she", "up."],
        options: ["stood", "standed", "stind"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, he", "the game."],
        options: ["lost", "losed", "lose"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, the car"],
        options: ["stopped", "stap", "steep"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, they", "to each other."],
        options: ["talked", "toked", "tald"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, I", "sugar."],
        options: ["added", "aid", "at"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, they", "an armchair."],
        options: ["moved", "mave", "mive"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last class, she", "some lines."],
        options: ["highlighted", "highlet", "highlate"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, she"],
        options: ["yawned", "yown", "yain"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "stand", past: "stood" },
        { verb: "lose", past: "lost" },
        { verb: "stop", past: "stopped" },
        { verb: "talk", past: "talked" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "add", past: "added" },
        { verb: "move", past: "moved" },
        { verb: "highlight", past: "highlighted" },
        { verb: "yawn", past: "yawned" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Last night, she", "homework."],
        options: ["did", "doed", "dote"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, he", "a ball."],
        options: ["hit", "hitted", "hut"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, the water"],
        options: ["spilt", "spull", "spall"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, the sun"],
        options: ["rose", "rised", "rase"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "on a dress."],
        options: ["tried", "tray", "tay"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, they", "to music."],
        options: ["listened", "lasten", "listed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, the sign", "hamburgers."],
        options: ["advertised", "advertose", "advertide"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, they"],
        options: ["argued", "argd", "argud"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "do homework", past: "did" },
        { verb: "hit", past: "hit" },
        { verb: "spill", past: "spilt" },
        { verb: "rise", past: "rose" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "try", past: "tried" },
        { verb: "listen", past: "listened" },
        { verb: "advertise", past: "advertised" },
        { verb: "argue", past: "argued" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Earlier, she", "sad."],
        options: ["was", "were", "ben"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Two weeks ago, she", "English."],
        options: ["taught", "teached", "tiched"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, the fan", "air."],
        options: ["blew", "blowed", "blowd"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, he", "some food."],
        options: ["ordered", "ordar", "orderd"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he", "a rope."],
        options: ["pulled", "puld", "pall"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last week, it"],
        options: ["rained", "roin", "raight"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, the store", "T-shirts."],
        options: ["displayed", "disploy", "desplat"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Just now, he", "the road."],
        options: ["crossed", "crass", "crease"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "she is", past: "was" },
        { verb: "teach", past: "taught" },
        { verb: "blow", past: "blew" },
        { verb: "order", past: "ordered" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "pull", past: "pulled" },
        { verb: "rain", past: "rained" },
        { verb: "display", past: "displayed" },
        { verb: "cross", past: "crossed" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["A moment ago, she", "a book."],
        options: ["got", "geted", "gate"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, a dog", "me."],
        options: ["bit", "bited", "bitted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, I", "some money."],
        options: ["tore", "teared", "teart"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, he", "a wall."],
        options: ["built", "bailed", "boiled"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, she", "her ID."],
        options: ["showed", "showt", "shaw"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last week, he", "a bin."],
        options: ["emptied", "empoy", "empted"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, he", "his friend."],
        options: ["encouraged", "encarage", "encouruge"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, she", "some food."],
        options: ["served", "saved", "serd"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "get", past: "got" },
        { verb: "bite", past: "bit" },
        { verb: "tear", past: "tore" },
        { verb: "build", past: "built" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "show", past: "showed" },
        { verb: "empty", past: "emptied" },
        { verb: "encourage", past: "encouraged" },
        { verb: "serve", past: "served" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Last semester, she", "two pens."],
        options: ["had", "haved", "hove"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, she", "up clothes."],
        options: ["hung", "hing", "haing"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, I", "an email."],
        options: ["sent", "sended", "sond"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, she", "a flower."],
        options: ["smelled", "small", "smill"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last summer, she", "ice cream."],
        options: ["liked", "lict", "lied"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, she", "coffee."],
        options: ["poured", "pourd", "port"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, I", "money."],
        options: ["exchanged", "exchonge", "excharge"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he", "at a TV."],
        options: ["pointed", "punt", "poined"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "she has", past: "had" },
        { verb: "hang", past: "hung" },
        { verb: "send", past: "sent" },
        { verb: "smell", past: "smelled" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "like", past: "liked" },
        { verb: "pour", past: "poured" },
        { verb: "exchange", past: "exchanged" },
        { verb: "point", past: "pointed" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["This morning, he", "some groceries."],
        options: ["bought", "buyed", "bay"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, he"],
        options: ["drove", "drived", "droove"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Two weeks ago, she", "a hole."],
        options: ["dug", "digged", "daiged"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, I", "a USB stick."],
        options: ["inserted", "insort", "insed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "the floor."],
        options: ["cleaned", "cleant", "clan"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, he", "the ball."],
        options: ["kicked", "kack", "kiced"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, I", "the drone."],
        options: ["controlled", "contrall", "controd"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "him."],
        options: ["ignored", "ignare", "inort"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "buy", past: "bought" },
        { verb: "drive", past: "drove" },
        { verb: "dig", past: "dug" },
        { verb: "insert", past: "inserted" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "clean", past: "cleaned" },
        { verb: "kick", past: "kicked" },
        { verb: "control", past: "controlled" },
        { verb: "ignore", past: "ignored" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Earlier, I", "a pizza slice."],
        options: ["took", "taked", "tuke"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, she"],
        options: ["thought", "thinked", "thunk"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "into water."],
        options: ["dived", "daved", "divet"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last Christmas, it"],
        options: ["snowed", "snew", "snode"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, he"],
        options: ["walked", "walkt", "walt"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, he", "his hands."],
        options: ["washed", "wosh", "wast"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, they", "her."],
        options: ["interviewed", "interveen", "intervied"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["This morning, the water", "the light."],
        options: ["reflected", "refract", "refled"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "take", past: "took" },
        { verb: "think", past: "thought" },
        { verb: "dive", past: "dived" },
        { verb: "snow", past: "snowed" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "walk", past: "walked" },
        { verb: "wash", past: "washed" },
        { verb: "interview", past: "interviewed" },
        { verb: "reflect", past: "reflected" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["Yesterday, she", "on the board."],
        options: ["drew", "drawed", "drawt"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last month, he", "a mug."],
        options: ["broke", "breaked", "brete"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Two weeks ago, he", "a fish."],
        options: ["caught", "caudt", "cutch"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, AC", "the room."],
        options: ["cooled", "cull", "cood"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he", "some food."],
        options: ["cooked", "cuck", "coode"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he", "a tree."],
        options: ["climbed", "clomed", "combed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last year, they", "a building."],
        options: ["constructed", "constrict", "custruct"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, I", "an email."],
        options: ["received", "receft", "receid"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "draw", past: "drew" },
        { verb: "break", past: "broke" },
        { verb: "catch", past: "caught" },
        { verb: "cool", past: "cooled" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "cook", past: "cooked" },
        { verb: "climb", past: "climbed" },
        { verb: "construct", past: "constructed" },
        { verb: "receive", past: "received" },
      ],
    },
  ],
  [
    {
      category: "FILL",
      item: {
        question: ["This morning, he"],
        options: ["hid", "hided", "hood"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last weekend, he", "the floor."],
        options: ["swept", "swaped", "swain"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Earlier, she", "the dog."],
        options: ["fed", "feeded", "feed"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last night, she"],
        options: ["dreamed", "dreamd", "drome"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Two weeks ago, they"],
        options: ["danced", "dant", "dence"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["A moment ago, I", "my laptop."],
        options: ["closed", "closd", "clase"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Last week, he", "money."],
        options: ["deposited", "deposot", "deposid"],
      },
    },
    {
      category: "FILL",
      item: {
        question: ["Yesterday, he", "someone."],
        options: ["rescued", "riscue", "rescude"],
      },
    },
    {
      category: "MATCH",
      item: [
        { verb: "hide", past: "hid" },
        { verb: "sweep", past: "swept" },
        { verb: "feed", past: "fed" },
        { verb: "dream", past: "dreamed" },
      ],
    },
    {
      category: "MATCH",
      item: [
        { verb: "dance", past: "danced" },
        { verb: "close", past: "closed" },
        { verb: "deposit", past: "deposited" },
        { verb: "rescue", past: "rescued" },
      ],
    },
  ],
];

export function getDailyChallenge(
  invalidateCurrentChallenge?: boolean
): DailyChallenge {
  const dailyChallenge: DailyChallenge = [];
  const getStoredDailyChallenge = localStorage.getItem(
    "past-tense-daily-challenge"
  );
  // if (!invalidateCurrentChallenge && getStoredDailyChallenge) {
  //   return JSON.parse(getStoredDailyChallenge);
  // }
  const currentChallenge = shuffle(
    challenge[Math.floor(Math.random() * challenge.length)]
  );

  currentChallenge.forEach((ch) => {
    const { category, item } = ch;
    if (category === "MATCH") {
      const [verbs, pastTense] = getShuffledVerb(item as Verb[]);
      dailyChallenge.push({
        category,
        item: {
          pastTense,
          verbs,
        },
      });
    } else {
      dailyChallenge.push({
        item: item as FillTask,
        category,
      });
    }
  });
  localStorage.setItem(
    "past-tense-daily-challenge",
    JSON.stringify(dailyChallenge)
  );
  return dailyChallenge;
}
export function getShuffledVerb(verbsDict: Verb[]): [Verb[], Verb[]] {
  const verbs: Verb[] = [];
  const pastTense: Verb[] = [];

  verbsDict.forEach((dict) => {
    const { verb, past } = dict;
    verbs.push({ verb, past, isCorrect: false });
    pastTense.push({ verb, past, isCorrect: false });
  });
  return [shuffle(verbs), shuffle(pastTense)];
}

export function getBestPos(
  array: LeaderBoard.Score[],
  element: number,
  start: number,
  end: number
): number {
  const mid = Math.floor((start + end) / 2);
  if (mid === end) return mid;
  if (element < array[mid].score && element >= array[mid + 1].score) {
    return mid - 1;
  } else if (element > array[mid].score) {
    return getBestPos(array, element, start, mid);
  } else {
    return getBestPos(array, element, mid + 1, end);
  }
}
