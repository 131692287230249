import React from "react";

const HeartIcon: React.FC<{ isEmpty?: boolean }> = ({ isEmpty = false }) => {
  return isEmpty ? (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.31768 17.7359L9.31443 17.7329L7.99 16.5084C7.98998 16.5084 7.98995 16.5083 7.98993 16.5083C5.9336 14.6012 4.15484 12.7849 2.65207 11.0591C1.19683 9.38801 0.5 7.67964 0.5 5.92499C0.5 4.54995 0.956766 3.42533 1.86605 2.51605C2.77649 1.60561 3.89333 1.14999 5.25 1.14999C6.01693 1.14999 6.78376 1.33358 7.55499 1.71149C8.29665 2.0749 8.97738 2.68152 9.5897 3.56074L9.97578 4.11512L10.3932 3.5839C11.0969 2.68822 11.8034 2.07217 12.5061 1.70587C13.2195 1.33399 13.9654 1.14999 14.75 1.14999C16.1067 1.14999 17.2235 1.60561 18.1339 2.51605C19.0432 3.42533 19.5 4.54995 19.5 5.92499C19.5 7.67964 18.8032 9.38801 17.3479 11.0591C15.8452 12.7848 14.0666 14.6011 12.0104 16.508C12.0103 16.5082 12.0101 16.5083 12.01 16.5084L10.6856 17.7329L10.6856 17.7328L10.6823 17.7359C10.4946 17.9126 10.2766 18 10 18C9.72341 18 9.50542 17.9126 9.31768 17.7359ZM9.79721 4.70139L9.79274 4.69245L9.78792 4.68369C9.30278 3.80325 8.66082 3.07837 7.86298 2.51625C7.05405 1.94633 6.17992 1.64999 5.25 1.64999C4.03173 1.64999 2.99857 2.04726 2.19436 2.86104C1.39179 3.67316 1 4.70848 1 5.92499C1 7.00691 1.36415 8.10919 2.04894 9.22417C2.71608 10.3104 3.50971 11.3638 4.42835 12.3845C5.34033 13.3978 6.28685 14.3402 7.26802 15.2114C8.23342 16.0686 9.03097 16.7872 9.66126 17.3678L9.99513 17.6753L10.3334 17.3726C10.9669 16.8058 11.767 16.089 12.7338 15.2223C13.7148 14.3427 14.6612 13.392 15.573 12.3704C16.4911 11.3419 17.2842 10.2849 17.9511 9.19917C18.6349 8.0858 19 6.9917 19 5.92499C19 4.70668 18.6028 3.67069 17.7911 2.85894C16.9808 2.04873 15.954 1.64999 14.75 1.64999C13.8074 1.64999 12.929 1.9397 12.1255 2.5031C11.3383 3.05498 10.6879 3.77923 10.1722 4.66607C10.1192 4.7493 10.0823 4.77524 10.0731 4.7806C10.0605 4.78793 10.0334 4.79999 9.975 4.79999C9.91731 4.79999 9.87903 4.78813 9.84955 4.77226C9.8476 4.77121 9.84276 4.76838 9.83487 4.75973C9.82644 4.7505 9.81292 4.73279 9.79721 4.70139Z"
        fill="#F65A5B"
        stroke="#FAFAFA"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.975 18.1L7.65 16.875C5.58333 14.9583 3.79167 13.1292 2.275 11.3875C0.758333 9.64583 0 7.82499 0 5.92499C0 4.42499 0.504167 3.17083 1.5125 2.16249C2.52083 1.15416 3.76667 0.649994 5.25 0.649994C6.1 0.649994 6.94167 0.854161 7.775 1.26249C8.60833 1.67083 9.35 2.34166 10 3.27499C10.7333 2.34166 11.4917 1.67083 12.275 1.26249C13.0583 0.854161 13.8833 0.649994 14.75 0.649994C16.2333 0.649994 17.4792 1.15416 18.4875 2.16249C19.4958 3.17083 20 4.42499 20 5.92499C20 7.82499 19.2417 9.64583 17.725 11.3875C16.2083 13.1292 14.4167 14.9583 12.35 16.875L11.025 18.1C10.7417 18.3667 10.4 18.5 10 18.5C9.6 18.5 9.25833 18.3667 8.975 18.1Z"
        fill="url(#paint0_linear_542_850)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_542_850"
          x1="10"
          y1="-3.99844"
          x2="10"
          y2="23.1484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F65A5B" />
          <stop offset="1" stop-color="#7F3E98" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HeartIcon;
