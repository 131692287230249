import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper";
import {
  DailyChallenge,
  FillTask,
  GameChallenge,
  getDailyChallenge,
  Verb,
} from "../past-tense";
import MatchVerb from "./MatchVerb";
import FillInTheBlanks from "./FillInTheBlanks";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectLife } from "../spokenEnglishSlice";

type GamePlayProps = {
  onGameOver: (reason: "COMPLETED" | "GAMEOVER") => void;
};

const GamePlay: React.FC<GamePlayProps> = ({ onGameOver }) => {
  const [dailyChallenge, setDailyChallenge] = useState<DailyChallenge>([]);
  const life = useSelector(selectLife);
  const swiper = useRef<SwiperClass>();

  useEffect(() => {
    setDailyChallenge(getDailyChallenge());
  }, []);

  const handleOnGameFinish = () => {
    if (!swiper.current) return;

    if (!swiper.current.isEnd) {
      swiper.current.slideNext();
    } else {
      onGameOver("COMPLETED");
    }
  };

  const getGameElement = (challenge: GameChallenge) => {
    if (challenge.category === "MATCH")
      return (
        <MatchVerb
          onGameFinished={handleOnGameFinish}
          pastTense={
            (challenge.item as { verbs: Verb[]; pastTense: Verb[] }).pastTense
          }
          verbs={(challenge.item as { verbs: Verb[]; pastTense: Verb[] }).verbs}
        />
      );
    else
      return (
        <FillInTheBlanks
          question={(challenge.item as FillTask).question}
          options={(challenge.item as FillTask).options}
          onGameFinish={handleOnGameFinish}
        />
      );
  };
  return (
    <div className={clsx("game-play", life === 0 && "deactivate")}>
      <Swiper
        className="swiper"
        autoHeight
        spaceBetween={50}
        slidesPerView={1}
        noSwiping={true}
        noSwipingClass="swiper-slide"
        mousewheel={{
          invert: false,
        }}
        keyboard={{
          enabled: false,
        }}
        onSwiper={(SwiperClass) => (swiper.current = SwiperClass)}
        style={{ width: "100%", height: "100%" }}
      >
        {dailyChallenge?.map((challenge, index) => {
          return (
            <SwiperSlide key={index}>{getGameElement(challenge)}</SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default GamePlay;
