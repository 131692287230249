import React from "react";

const HeartIcon: React.FC<{ isEmpty?: boolean }> = ({ isEmpty = false }) => {
  return (
    <div className={isEmpty ? "empty" : "fill"}>
      <svg viewBox="0 0 24 24">
        <use xlinkHref="#heart" />
        <use xlinkHref="#heart" />
      </svg>
      <svg className="hide" viewBox="0 0 24 24">
        <defs>
          <path
            id="heart"
            d="M12 4.419c-2.826-5.695-11.999-4.064-11.999 3.27 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-7.327-9.17-8.972-12-3.27z"
          />
        </defs>
      </svg>
    </div>
  );
};

export default HeartIcon;
