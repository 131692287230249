import React from "react";
import { Link } from "react-router-dom";

type StartScreenProps = {
  onStart: () => void;
};

const StartScreen: React.FC<StartScreenProps> = ({ onStart }) => {
  return (
    <React.Fragment>
      <div className="start-screen">
        <h1>Spoken English</h1>
        <h2>Past Tense</h2>
      </div>
      <div className="btn-container">
        <Link to="./leaderboard">
          <button className="btn-spoken scores">Scores</button>
        </Link>
        <button className="btn-spoken start" onClick={onStart}>
          start
        </button>
      </div>
    </React.Fragment>
  );
};

export default StartScreen;
