import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import EGButton from "../../components/Button/Button";
import InstructionsModal from "./InstructionsModal";
import { loadLeaderBoard, selectLeaderBoard } from "./leaderboardSlice";
import TrophyIcon from "../../assets/images/trophy.svg";
import FifaBanner from "../../assets/images/fifa_banner.png";

interface StartScreenProps {
    onStartClick: () => void;
}

const StartScreen: React.FC<StartScreenProps> = ({
    onStartClick,
}) => {
    const dispatch = useAppDispatch();
    const [showInstructions, setShowInstructions] = useState(false);
    const leaderboard = useSelector(selectLeaderBoard);

    useEffect(() => {
        dispatch(loadLeaderBoard())
    }, [])

    return (
        <React.Fragment>
            <div className='wc-start-screen'>
                <img src={FifaBanner} className="banner" />
                <div className="leaderboard-list">
                    <table>
                        <tbody>
                            {!leaderboard.isLoading &&
                                leaderboard.data &&
                                leaderboard.data?.map((board, index) => (
                                    <tr
                                        key={`${board.name}-${index}`}
                                    >
                                        <td>{index + 1}</td>
                                        <td className="align-left">
                                            {board.name}{" "}
                                            {index < 3 && <img src={TrophyIcon} alt="trophy" />}{" "}
                                        </td>
                                        <td>{board.score}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <EGButton
                    style={{ margin: '16px 0' }}
                    onClick={onStartClick}
                >
                    Start
                </EGButton>
                <span
                    style={{ fontWeight: 600, fontSize: '0.75rem' }}
                    onClick={() => setShowInstructions(true)}
                >
                    Terms and Conditions
                </span>
            </div>
            <InstructionsModal
                open={showInstructions}
                onClose={() => setShowInstructions(false)}
            />
        </React.Fragment>

    )
}

export default StartScreen;