import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentQuestionCount } from "../slice";
import HeartIcon from "./HeartIcon";

type LifeProps = {};

const Life: React.FC<LifeProps> = () => {
  const currentQuestionCount = useSelector(selectCurrentQuestionCount);

  return (
    <div className="life">
      {new Array(5)
        .fill("")
        .map((_, index) =>
          index + 1 <= currentQuestionCount ? (
            <HeartIcon isEmpty={false} key={`life-${index}`} />
          ) : (
            <HeartIcon isEmpty key={`life-${index}`} />
          )
        )}
    </div>
  );
};

export default Life;
