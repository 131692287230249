import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export const MAX_LIFE = 3;

const initialState = {
  currentQuestionCount: 1,
  score: 0,
};

export const valentinesDaySlice = createSlice({
  name: "valentinesDay",
  initialState,
  reducers: {
    incrementQuestion: (state, action: PayloadAction<number>) => {
      state.currentQuestionCount = Math.min(5, action.payload);
    },
    decrementQuestion: (state) => {
      state.currentQuestionCount = Math.max(0, state.currentQuestionCount - 1);
    },
    incrementScore: (state) => {
      state.score = Math.min(50, state.score + 10);
    },
    decrementScore: (state) => {
      state.score = Math.max(0, state.score - 10);
    },
    resetGame: (state) => initialState,
  },
});

export const {
  incrementQuestion,
  decrementQuestion,
  incrementScore,
  decrementScore,
  resetGame,
} = valentinesDaySlice.actions;

export const selectCurrentQuestionCount = (state: RootState) =>
  state.valentinesDay.currentQuestionCount;

export const selectScore = (state: RootState) => state.valentinesDay.score;

export default valentinesDaySlice.reducer;
